import React from "react";
import { Link } from "react-router-dom";
import "./SportingSanction.scss";
import Layout from "../../../layouts/Layout";
import NewsGrid from "../../../shared/News/NewGrid";

const SportingSanction = () => {
    return (
        <>
            <Layout>
                <div className="back-button">
                    <Link to="/news">
                        <i className="pi pi-arrow-left"></i>
                        Back to News
                    </Link>
                </div>
                <section className="news-tab">
                    <div className="grid">
                        <div className="col-12">
                            <NewsGrid title={'All Sanctions'} type={2} upcomingWeekNews={false}></NewsGrid>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default SportingSanction;
