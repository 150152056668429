import { useState, useEffect, useMemo } from "react";
import * as PR from "../prime-react/index";
import { getFileDetails } from "../services/api";
import { useDispatch, useSelector } from "react-redux";
import { profileIcon } from "../assets/images";
import { retrieveImageBlob, storeImageBlob } from "../utils/profileImage";
import secureLocalStorage from "react-secure-storage";

const ProfileImageDisplay = ({ profileImage }) => {
    const dispatch = useDispatch();
    const sessionData = useSelector(state => state.auth.sessionData);
    const headers = useMemo(() => ({ sessionid: sessionData.sessionId }), [sessionData.sessionId]);
    const [displayImage, setDisplayImage] = useState(`${profileIcon}?v=${new Date().getTime()}`);

    const blobKey = secureLocalStorage.getItem("userImage")

    useEffect(() => {
        if(blobKey){
            const blob = retrieveImageBlob("userImage")
            setDisplayImage(URL.createObjectURL(blob));
        }else if (!blobKey && profileImage) {
            if (profileImage?.key && (profileImage.type === "image/jpeg" || profileImage.type === "image/jpg" || profileImage.type === "image/png")) {
                getFileDetails(profileImage.key, headers, dispatch, response => {
                    if (response.result === 'SUCCESS') {
                        setDisplayImage(URL.createObjectURL(response?.data));
                        storeImageBlob("userImage", response?.data); 
                    }
                });
            } 
        }else{
            setDisplayImage(`${profileIcon}?v=${new Date().getTime()}`)
        }
    }, [blobKey, dispatch, headers, profileImage]);

    return (
        <PR.Image
            src={displayImage}
            className="group-name-icon"
        />
    );
};

export default ProfileImageDisplay;
