import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Layout from "../../../../layouts/Layout";
import * as PR from "../../../../prime-react/index";
import { getFileDetails, getGroupsWithPermission, getNewsCategories, getNewsDetails } from "../../../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { newsActions } from "../../../../store/news";
import { fileUploadLimit, getNewsTypeLabel, maxNumberOfFiles, newsFileTypesConfig, permission, roleIds } from "../../../../utils/reuse";
import { ErrorMessage, useToast } from "../../../../context/ToastContext";
import DOMPurify from "dompurify";

const EditDetails = () => {
  const { showToast, clearError } = useToast();
  const [newsDetail, setNewsDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { search, state } = useLocation();
  const queryParams = new URLSearchParams(search);
  const [newsType, setnewsType] = useState('');
  const id = queryParams.get("id");
  const dispatch = useDispatch()
  const fileUploadRef = useRef(null);
  const sessionData = useSelector((state) => state.auth.sessionData);
  const headers = useMemo(() => {
    return {
      sessionid: sessionData.sessionId,
    };
  }, [sessionData.sessionId]);

  const [categories, setCategories] = useState([]);
  const [regulators, setRegulators] = useState([]);
  const [governingBodies, setGoverningBodies] = useState([]);

  const [deletedFiles, setDeletedFiles] = useState([]);
  const { pathname } = useLocation();

  const [initialRegulators, setInitialRegulators] = useState([]);
  const [initialGoverningBodies,setInitialGoverningBodies] = useState([]);
  const [initialAllMembers,setInitialAllMembers] = useState(false);
  const [fileUrls, setFileUrls] = useState([]);
  const [attachmentImage, setAttachmentImage] = useState(null);
  const [initialCoverImage, setInitialCoverImage] = useState(null);
  const [initialLoad, setInitialLoad] = useState(false);
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    getNewsCategories(headers, dispatch, (response) => {
        if (response.result === "SUCCESS") {
          const responseData = response.data;
          const result = responseData?.length > 0 ? responseData?.sort((a, b) => a.name.localeCompare(b.name)) : [];
          setCategories(result);
        } else {
          showToast("error", "Failed to fetch categories");
        }
      },
      false,
      false
    );

    getGroupsWithPermission(roleIds?.regulator,permission?.news,  headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        const responseData = response.data;
        const result = responseData?.length > 0 ? responseData : [];
        setRegulators(result);
      } else {
        showToast("error", "Failed to fetch regulators");
      }
    });

    getGroupsWithPermission(roleIds?.governingbody,permission?.news, headers, dispatch, (response) => {
      if (response.result === "SUCCESS") {
        setInitialLoad(true);
        const responseData = response.data;
        const result = responseData?.length > 0 ? responseData : [];
        setGoverningBodies(result);
      } else {
        setInitialLoad(true);
        showToast("error", "Failed to governing bodies");
      }
    });
  }, [headers, dispatch, showToast]);
  useEffect(() => {
    setLoading(true)
    if(!state?.formvalues){
      if(initialLoad){
        getNewsDetails(id, headers, dispatch, (response) => {
          if (response.result === 'SUCCESS') {
            const responseData = response.data?.length ?  response.data : [];
            const newsData = responseData.map(news => ({
              ...news,
              fileAttachments: news?.fileAttachments ? JSON.parse(news.fileAttachments) : []
            }));
            if(newsData?.length){
              setNewsDetail(newsData[0]);
              setnewsType(newsData[0]?.newsType);
            } else {
              navigate('/news')
            }
          } else {
            navigate('/news');
          }
          setLoading(false)
        })
      }
    } else {
      setLoading(false)
      setInitialAllMembers(state?.initialAllMembers);
      setInitialGoverningBodies(state?.initialGoverningBodies);
      setInitialRegulators(state?.initialRegulators);
      setAttachmentImage(state?.attachmentImage);
      setInitialCoverImage(state?.initialCoverImage);
      setDeletedFiles(state?.deletedAttachments);
      setFileUrls(state?.fileUrls);
      setnewsType(state?.newsType);
    }
  }, [clearError, dispatch, headers, id, showToast, state?.formvalues, state?.initialAllMembers, state?.initialGoverningBodies, state?.initialRegulators, state?.attachmentImage, state?.initialCoverImage, state?.deletedAttachments, state?.fileUrls, navigate, state?.newsType, initialLoad]);
  useEffect(() => {
    if (newsDetail?.fileAttachments) {
      setLoading(true)
      const attachmentsArray = newsDetail?.fileAttachments
      if (attachmentsArray && attachmentsArray?.length > 0) {
        attachmentsArray.forEach(attachment => {
          if (attachment.attachmentType === "coverImage") {
            getFileDetails(attachment.key, headers, dispatch, response => {
              if (response.result === 'SUCCESS') {
                const url = URL.createObjectURL(response.data);
                if (attachment.type === "image/jpeg" || attachment.type === "image/png") {
                  setAttachmentImage(url);
                  setInitialCoverImage({
                    key: attachment.key,
                    type: attachment.type,
                    attachmentType: "coverImage"
                  })
                }
              } else {
                const error = response?.error;
                showToast(error.severity, error?.errorMsg || error?.summary);
              }
            })
          } else {
            setFileUrls(prev => {
              if (!prev.some(file => file.key === attachment.key)) {
                return [...prev, { name: attachment.name, key: attachment.key, type: attachment.type }]
              }
              return prev
            });
          }
        })
      };
      setLoading(false)
    }
  }, [dispatch, headers, newsDetail?.fileAttachments, showToast])
  useEffect(() => {
    if (state?.initialCoverImage && state.attachmentImage && state.attachmentImage?.includes('blob')) {
      getFileDetails(state?.initialCoverImage.key, headers, dispatch, response => {
        if (response.result === 'SUCCESS') {
          const url = URL.createObjectURL(response.data);
          setAttachmentImage(url);
        }
      })
    }
  }, [dispatch, headers, state?.attachmentImage, state?.initialCoverImage])

 

  const validationSchema = Yup.object({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    keywords: Yup.array().of(Yup.string()),
    attachments: Yup.array()
    .max(maxNumberOfFiles, `You can upload a maximum of ${maxNumberOfFiles} files`)
    .nullable()
    .test('fileType', 'Only image, PDF, DOCX or XLSX files are allowed', (files) => {
      if (!files) return true; // If no file is provided, return true
      return files?.every((file) => newsFileTypesConfig.includes(file.type))
    })
    .test('fileSize', `File size must be less than ${fileUploadLimit.label}`, (files) => {
      if (!files) return true;
      return files?.every((file) => {
        return file.size <= fileUploadLimit.value
      })
    }),
    category: Yup.object().when("newsType", {
      is: (value) => value !== "Integrity previews",
      then: (schema) => schema.required("Category is required"),
      otherwise: (schema) => schema,
    }),
    pushTo: Yup.object({
      members: Yup.boolean(),
      regulators: Yup.array().of(Yup.object()),
      governingBodies: Yup.array().of(Yup.object()),
    }).test(
      "pushToValidation",
      "At least one push target is required",
      (value) => {
        const { members, regulators, governingBodies } = value;
        return members || regulators?.length > 0 || governingBodies?.length > 0;
      }
    ),
  });
  const imageUploadRef = useRef(null);
  const [initialValues, setInitialValues ] = useState( {
    title: "",
    coverImage:null,
    attachments: [],
    description: "",
    keywords:[],
    category: "",
    newsType: newsType || "",
    pushTo: {
      members: false,
      regulators: [],
      governingBodies: [],
    },
  })
  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    enableReinitialize:true,
    onSubmit: (values) => {

      const payload = {
        id:Number(id),
        title: values.title,
        description: values.description,
        newsType: newsType.toLowerCase().replace(/\s/g, ""),
        keywords: values?.keywords?.join(','),
        categoryID: categories?.find(
          (category) => category.name === values.category.name
        )?.id,
        receiverAllMembers: values.pushTo.members,
        receivers: [
          ...(values?.pushTo?.governingBodies||[])?.filter(body => !initialGoverningBodies?.some(item => item.id === body.id))?.map((body) =>body.id),
          ...(values?.pushTo?.regulators||[])?.filter(regulator => !initialRegulators?.some(item => item.id === regulator.id))?.map((regulator) =>regulator.id),
        ]
      };

      // Remove `receiverAllMembers` if `newsDetail.receiverAllMembers` is true
      if (newsDetail?.receiverAllMembers === true || state?.receiverAllMembers === true) {
        delete payload?.receiverAllMembers;
      }
      const attachments = [];
      if (values.attachments?.length > 0) {
        let newAttachments = values.attachments

        if(fileUrls.length >0){
          newAttachments = values.attachments.filter(attachment => {
            return !fileUrls.some(file => file.key === attachment.key);
          })
        }

        newAttachments?.forEach((file, index) => {
          attachments.push(file);
        });

      }

      let deletedAttachments = [];
      if(deletedFiles){
        deletedAttachments = [...deletedFiles]
      }
      if(initialCoverImage && values.coverImage){
        deletedAttachments.push(initialCoverImage)
      }

      const coverImage = values.coverImage ? values.coverImage : attachmentImage;

      navigate("/preview-news" , {state: {formvalues: values, payload, coverImage,  attachments, initialGoverningBodies, initialRegulators, attachmentImage, initialAllMembers, receiverAllMembers: newsDetail?.receiverAllMembers , deletedAttachments, initialCoverImage, fileUrls, newsType, queryParams: { id} }});
    },
  });
  useEffect(() => {
    switch(newsType){
      case "sportingsanctions":
        dispatch(newsActions.updateTab({activeIndex:2}))
        return;
      case"integritypreviews":
        dispatch(newsActions.updateTab({activeIndex:1}))
        return;
      default:
        dispatch(newsActions.updateTab({activeIndex:0}))
        return;
    }
  },[newsType, dispatch])

  useEffect(() => {
    if (newsDetail) {
      const governingBodyOptions = governingBodies.map(g => g.id);
      const regulatorOptions = regulators.map(r => r.id);
      const savedGoverningBody = newsDetail.receivers?.filter(receiver => receiver.role === "governingbody" && governingBodyOptions.includes(receiver.id));
      const savedRegulators = newsDetail.receivers?.filter(receiver => receiver.role === "regulator" && regulatorOptions.includes(receiver.id));
      const description = newsDetail?.description?.replace(/\s+(<\/\w+>)/g, '$1');

      setInitialValues(p=>({
        ...p,
        title: newsDetail?.title,
        description: DOMPurify.sanitize(description),
        keywords: newsDetail?.keywords?.split(','),
        category: {id:newsDetail?.categoryID,name:newsDetail?.categoryName },
        pushTo:{
          ...p.pushTo,
          members: newsDetail?.receiverAllMembers,
          regulators: savedRegulators?.map(r =>({...r,disabled:true})),
          governingBodies: savedGoverningBody?.map(r =>({...r,disabled:true}))
        }
      }));
      setInitialAllMembers((newsDetail?.receiverAllMembers === undefined) ? false : newsDetail.receiverAllMembers)
      setInitialRegulators(savedRegulators)
      setInitialGoverningBodies(savedGoverningBody)
    }
  }, [governingBodies, newsDetail, regulators]);

 
  useEffect(() => {
    if (newsDetail?.fileAttachments) {
      const attachmentsArray = newsDetail?.fileAttachments
      if (attachmentsArray && attachmentsArray?.length > 0) {

        let files = []
        attachmentsArray?.forEach(attachment => {
          if(attachment?.attachmentType !== 'coverImage') {
            files.push({name: attachment.name, key: attachment.key, type: attachment.type, size:Number(attachment.size) })
          }
        })
        setInitialValues(p=>({...p,"attachments":[...files]}))
      }
    }
  },[newsDetail])

  useEffect(() => {
    if(state?.formvalues){
      setInitialValues(state?.formvalues)
    }
  }, [state?.formvalues])

  const renderHeader = () => {
    return (
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-list" value="ordered"></button>
        <button className="ql-list" value="bullet"></button>
      </span>
    );
  };

  const header = renderHeader();

  const disableSelectedOptions = (options,selectedOptions) =>{
    return options?.map(option => {
      const isDisabled = selectedOptions?.some(so =>so.id===(option.id))
      const {id,name,role} = option
      return {
        id,
        name,
        role,
        disabled: isDisabled,
      }
    })?.sort((a, b) => a.name.localeCompare(b.name))
  }

  const handleFileSelect = (event) => {
    if (fileUploadRef.current) {
        fileUploadRef.current.clear();
    }
    const currentAttachments = formik.values.attachments || [];
    const newAttachment = event.files[0];
    const duplicateFiles = currentAttachments?.some(existingFile => existingFile.name === newAttachment.name)
    if (duplicateFiles) {
      showToast("warn", "File names must be unique. Please rename your file(s) before uploading.");
      fileUploadRef.current.clear();
    } else if (currentAttachments?.length + 1 > maxNumberOfFiles) {
      showToast("warn", `You can upload a maximum of ${maxNumberOfFiles} files (including existing and new files).`);
      fileUploadRef.current.clear();
    } else {
      const updatedAttachments = [...currentAttachments, newAttachment];
      formik.setFieldValue("attachments", updatedAttachments);
    }
  };
  const handleDeleteCoverImage = () => {
    setDeletedFiles(prevDeleted => [
      ...prevDeleted,
      initialCoverImage
    ]);
    setAttachmentImage(false)
  }
  const handleDeleteCoverImageFile = () => {
    formik.setFieldValue("coverImage", null);
    if(initialCoverImage){
      setDeletedFiles(prevDeleted => [
        ...prevDeleted,
        initialCoverImage
      ]);
    }
  }

  const handleDeleteFile = (index) => {
    const deletedFile = formik.values.attachments[index];
      if (deletedFile.key) {
          setDeletedFiles(prevDeleted => [
              ...prevDeleted,
              { key: deletedFile.key, type: deletedFile.type }
          ]);
      }

    const updatedAttachments = [...formik.values.attachments];
    updatedAttachments.splice(index, 1);
    formik.setFieldValue("attachments", updatedAttachments);
  };

  return (
    <>
      <Layout backBtn={'news'} backBtnLabel="News">
        <section className="news-tab news-details-section general-news-section">
          {
            loading &&
            <div className="loading">
              <i className="pi pi-spin pi-spinner"></i>
            </div>
          }
          <div className="flex align-items-center justify-content-between mb-4">
            <div className="left">
              <h1>
                Edit {getNewsTypeLabel(newsType)}
              </h1>
            </div>
            <div className="center">
              <ErrorMessage id={`report_api_error`} />
            </div>
            <div className="right">
              <PR.Button
                type="submit"
                label="Update & Preview"
                className="action-buttons save-button"
                onClick={formik.handleSubmit}
                disabled={state?.formvalues ? false : (!formik.isValid || formik.isSubmitting || !(formik.dirty || typeof attachmentImage==="boolean"))}
              />
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="grid">
              <div className="col-12">
                <div className="new-list-section">
                  <div className="list-wrapper">
                    <div className="news-editScreen edit-details">
                      <div className="card">
                        <div className="col-12 p-0 mb-4">
                          <div className="flex flex-column form-input gap-2">
                            <label htmlFor="title">
                              News title <span className="p-error">*</span>
                            </label>
                            <PR.InputText
                              id="title"
                              type="text"
                              value={formik.values.title}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className={`input-field ${
                                formik.touched.title && formik.errors.title
                                  ? "p-invalid"
                                  : ""
                              }`}
                            />
                            {formik.touched.title && formik.errors.title ? (
                              <small className="p-error">
                                {formik.errors.title}
                              </small>
                            ) : null}
                          </div>
                        </div>
                        {newsType === "sportingsanctions" && (
                          <div className="form-input-row">
                            <div className="col-12 p-0 mb-4">
                              <div  className="flex flex-column form-input gap-2">
                                <label htmlFor="keywords">Keywords</label>
                                <PR.Chips 
                                  className="chips-field"
                                  inputId="keywords"
                                  value={formik.values.keywords}
                                  onChange={(e) => formik.setFieldValue('keywords', e.value)}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="col-12 p-0">
                          <div className="flex flex-column gap-2">
                            {!attachmentImage?(
                              <>
                                <span className="label">
                                  Attach Cover Image
                                </span>
                                <div className="card create-file-upload flex align-items-center">
                                  <PR.FileUpload
                                    mode="advanced"
                                    name="coverImage"
                                    accept="image/*"
                                    chooseLabel="Choose Image"
                                    maxFileSize={fileUploadLimit.value}
                                    ref={imageUploadRef}
                                    customUpload
                                    onSelect={(event) => {
                                      if (imageUploadRef.current) {
                                        imageUploadRef.current.clear();
                                      }
                                      formik.setFieldValue("coverImage", event.files[0]);
                                    }}
                                    />
                                  <span className="ml-2">
                                    (Add image less than {fileUploadLimit.label})
                                  </span>
                                </div>
                                {formik.values.coverImage && (
                                  <div className="uploaded-file-container flex flex-column gap-2">
                                    <div className="uploaded-files">
                                      {formik.values.coverImage.name}
                                      <i className="pi pi-trash" onClick={handleDeleteCoverImageFile} />
                                    </div>
                                  </div>
                                )}
                              </>
                            )
                            :(<div className="edit-file-upload">
                              <PR.FileUpload
                               mode="basic"
                               name="coverImage"
                               accept="image/*"
                               chooseLabel="Choose Image"
                               maxFileSize={fileUploadLimit.value}
                               customUpload
                               onSelect={(event) => {
                                setAttachmentImage(null)
                                 formik.setFieldValue("coverImage", event.files[0]);
                               }}
                              />
                              {attachmentImage && <PR.Image
                                src={attachmentImage}
                                alt="Weekly Integrity Preview"
                                className="new-edit-image"
                              />}
                              <i
                                className="pi pi-trash cover-image-delete"
                                onClick={() => handleDeleteCoverImage()}
                              />
                              
                            </div>)}
                          </div>
                        </div>
                        <div className="col-12 p-0">
                          <div className='attachments'>
                            <span className='label '>Attached Files</span>
                            <div className="attach-files mt-2">
                              <div className="card create-file-upload flex align-items-center">
                                <PR.FileUpload
                                  auto
                                  mode="advanced"
                                  name="attachments"
                                  accept={newsFileTypesConfig?.toString()}
                                  chooseLabel="Choose Files"
                                  multiple
                                  ref={fileUploadRef}
                                  onSelect={handleFileSelect}
                                  onFocus={() => formik.setFieldTouched("attachments")}
                                  customUpload
                                />
                                <span className="ml-2">
                                  (Add up to {maxNumberOfFiles} files less than {fileUploadLimit.label} each)
                                </span>
                              </div>
                            </div>
                            {formik.values.attachments && (
                                <div className="uploaded-file-container attachments-upload">
                                    {formik.values.attachments?.map((attachment, index) => (
                                      <div className="uploaded-files" key={index}>
                                        {attachment.name}
                                        <i
                                          className="pi pi-trash"
                                          onClick={() => handleDeleteFile(index)}
                                          style={{ cursor: 'pointer', marginLeft: '10px' }}
                                        />
                                      </div>
                                    ))}
                                </div>
                            )}

                            {formik.errors.attachments && (formik.touched.attachments || formik.submitCount > 0) && (
                              <div className="error">
                                {Array.isArray(formik.errors.attachments) ? (
                                    Array.from(new Set(formik.errors?.attachments)).map((error, index) => (
                                        <div key={'attachment' + index}>{error}</div>
                                    ))
                                ) : (
                                    <div className="error-message mt-1">{formik.errors.attachments}</div>
                                )}
                              </div>
                            )}


                          </div>
                        </div>
                      <div className="col-12 p-0 mt-4">
                        <div className="flex flex-column gap-2">
                          <span className="label">News description
                            <span className="important">*</span>
                          </span>
                            <div className="card content-editor">
                              <PR.Editor
                                id="description"
                                name="description"
                                value={formik.values.description}
                                onTextChange={e => {
                                  formik.setFieldValue('description', e.htmlValue);
                                }}
                                headerTemplate={header}
                                style={{ height: "450px" }}
                              />
                            </div>
                        </div>
                      </div>
                      {newsType !== "integritypreviews" && (
                        <div className="col-12 p-0 mt-4">
                          <div className="flex flex-column form-input gap-2">
                            <label htmlFor="category">News category
                            <span className="important">*</span>
                            </label>
                            <PR.Dropdown
                              inputId="category"
                              name="category"
                              value={formik.values.category}
                              disabled={true}
                              options={categories}
                              optionLabel="name"
                              placeholder="Select a category"
                              className="w-full"
                            />
                          </div>
                        </div>
                      )}
                      <div className="grid mt-4">
                        <div className="col-12 pt-0 pb-0">
                          <span className="label">Publish and send by email to
                            <span className="important">*</span>
                          </span>
                        </div>
                        <div className="col-4">
                          <div className="flex form-input gap-2 align-items-center status-box">
                            <PR.Checkbox
                              inputId="members"
                              name="members"
                              value="All members"
                              onChange={(e) => formik.setFieldValue("pushTo.members", e.checked)}
                              checked={formik.values.pushTo.members}
                              disabled={initialAllMembers}
                            />
                            <label
                              htmlFor="members"
                              className="ml-2 font-medium"
                            >
                              All members
                            </label>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="card flex justify-content-center">
                            <PR.MultiSelect
                              inputId="regulators"
                              name="regulators"
                              value={formik.values.pushTo.regulators}
                              onChange={(e) => formik.setFieldValue("pushTo.regulators", e.value)}
                              options={disableSelectedOptions(regulators, initialRegulators)}
                              optionLabel="name"
                              filter
                              resetFilterOnHide
                              placeholder="Regulators"
                              maxSelectedLabels={3}
                              className="w-full"
                              showSelectAll
                              pt={{
                                  checkboxContainer: {
                                      onClick: (e) => {
                                          e.stopPropagation();
                                          const parent = e.target?.parentNode;
                                          if (parent && typeof parent.click === 'function') {
                                              parent.click();
                                          }
                                      },
                                  },
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="card flex justify-content-center">
                            <PR.MultiSelect
                              inputId="governingBodies"
                              name="governingBodies"
                              value={formik.values.pushTo.governingBodies}
                              onChange={(e) => formik.setFieldValue("pushTo.governingBodies", e.value)}
                              options={disableSelectedOptions(governingBodies, initialGoverningBodies)}
                              optionLabel="name"
                              filter
                              resetFilterOnHide
                              placeholder="Sport Governing Bodies"
                              maxSelectedLabels={3}
                              className="w-full"
                              showSelectAll
                              pt={{
                                  checkboxContainer: {
                                      onClick: (e) => {
                                          e.stopPropagation();
                                          const parent = e.target?.parentNode;
                                          if (parent && typeof parent.click === 'function') {
                                              parent.click();
                                          }
                                      },
                                  },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                          </div>
                        </div>
                  </div>
                  <div className="text-right mt-4">
                    <PR.Button
                      label="Update & Preview"
                      type="submit"
                      className="action-buttons save-button"
                      disabled={state?.formvalues ? false : (!formik.isValid || formik.isSubmitting || !(formik.dirty || typeof attachmentImage==="boolean"))}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </Layout>
    </>
  );
};

export default EditDetails;
