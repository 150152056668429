import { useState } from "react";
import * as PR from "../../prime-react/index";
import { getAlertDetails } from "../../services/api";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const AlertsSearchId = ({headers, goTo, disabledField}) => {
    const [alertIdSearch,setAlertIdSearch] = useState('');
    const [loading,setLoading] = useState(false);
    const [error, setError] = useState(null)
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const searchHandler =  e => {
        e?.preventDefault()
        if(!alertIdSearch) return;
        let legacyId = alertIdSearch;
        if(!alertIdSearch.toLowerCase().startsWith('alert-')){
            legacyId = `alert-${alertIdSearch}`
        }
        setLoading(true);
        getAlertDetails(legacyId, headers, dispatch, ({result, error, data})=>{
            const alertDetailsData = {result, error, data};
            if(result==='SUCCESS' && (data && data?.alertID)) {
                localStorage.setItem('pageRefreshed', false);
                navigate("/alert-details/" + data?.legacyID, {state: {alertDetailsData}});
            } else {
                setError("Alert ID is not found");
                setTimeout(() => {
                    setError(null);
                }, 2500);
            }
            setLoading(false);
        });
    }
    const isValidAlertId = (searchVal, matchValue) => {
        // Check if input starts with 'Alert-' and is followed by up to 4 digits
        if (searchVal.toLowerCase().startsWith(matchValue)) {
            const digits = searchVal.slice(matchValue.length);
            return digits.length <= 6 && Number(digits);
        }
        return false;
    };

    const inputSearchHandler = (e) => {
        const searchVal = e.target.value;
        const matchValue = 'Alert-'.toLowerCase();

        // Define conditions for when the input is valid
        const isEmptyInput = searchVal === '';
        const isNumericInput = Number(searchVal);
        const matchesPrefix = matchValue[searchVal?.length - 1] === searchVal.toLowerCase()[searchVal?.length - 1];
        const isValidIdFormat =
            matchValue.length <= searchVal.length &&
            Number(searchVal.slice(matchValue.length - 1)) &&
            isValidAlertId(searchVal, matchValue);
        // Handle setting alert ID when input matches prefix or is empty/valid
        if (matchesPrefix || isEmptyInput || isValidIdFormat) {
            setAlertIdSearch(searchVal);
        }
        if (searchVal.length <= 6 && (isNumericInput || isEmptyInput)) {
            setAlertIdSearch(searchVal);
        }
    }

    return <div className="flex align-items-center">
        <span className="p-input-icon-right">
            <form onSubmit={searchHandler}>
                <PR.InputText
                    id="alertLegacyId"
                    disabled={loading || disabledField}
                    placeholder="Search by Alert ID"
                    className="search-box pr-7"
                    value={alertIdSearch}
                    style={{width:'265px', fontSize:13, paddingTop:7, paddingBottom:7}}
                    autoComplete="off"
                    onChange={inputSearchHandler}
                />
                <p className="error-message mt-1 absolute">{error}</p>
            </form>
            <span className="right-search-icon">
                {!!alertIdSearch && <i style={{cursor:'pointer'}} onClick={()=>setAlertIdSearch('')} className="pi pi-times text-sm mr-2" />}
                {loading
                    ?<i className="pi pi-spin pi-spinner" />
                    :<i style={{cursor:'pointer'}} onClick={searchHandler} className="pi pi-search" />
                }
            </span>
        </span>
    </div>
}

export default AlertsSearchId;