import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const generateScreenContentPDF = async (report) => {
    const content = document.getElementById("report-content");
    if (!content) throw new Error("Report content element not found.");

    const hiddenClass = "hide-for-pdf";
    const style = document.createElement("style");
    style.textContent = `.${hiddenClass} { display: none !important; }`;

    const buttons = document.querySelectorAll(".hide-on-download");
    buttons.forEach((button) => button.classList.add(hiddenClass));
    document.head.appendChild(style);

    try {
        // Set a higher scale for better resolution
        const scale = 3; // Adjust as needed (higher scale = better quality but larger file size)
        const canvas = await html2canvas(content, { scale });
        const pdf = new jsPDF({ orientation: "portrait", unit: "mm", format: "a4" });

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const margin = 10;
        const headerHeight = 20;
        const bottomMargin = 10;
        const contentStartPosition = headerHeight + 10;
        const pageContentHeight = pdfHeight - contentStartPosition - bottomMargin;

        const imgWidth = pdfWidth - 2 * margin;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        let position = 0;

        pdf.setFontSize(8);
        pdf.text(report.title, margin, headerHeight);

        while (position < imgHeight) {
            const remainingHeight = imgHeight - position;
            const renderHeight = Math.min(pageContentHeight, remainingHeight);

            const canvasPart = document.createElement("canvas");
            canvasPart.width = canvas.width;
            canvasPart.height = renderHeight * (canvas.width / imgWidth);
            const ctx = canvasPart.getContext("2d");
            ctx.drawImage(
                canvas,
                0,
                position * (canvas.width / imgWidth),
                canvas.width,
                renderHeight * (canvas.width / imgWidth),
                0,
                0,
                canvasPart.width,
                canvasPart.height
            );

            const partImgData = canvasPart.toDataURL("image/png");
            pdf.addImage(partImgData, "PNG", margin, contentStartPosition, imgWidth, renderHeight, undefined, 'FAST');

            position += renderHeight;

            if (position < imgHeight) {
                pdf.addPage();
                pdf.text(report.title, margin, headerHeight);
            }
        }
        return pdf.output("blob");
    } finally {
        buttons.forEach((button) => button.classList.remove(hiddenClass));
        document.head.removeChild(style);
    }
};
