import React, { useEffect, useMemo, useRef, useState, useCallback } from "react";
import "./CreateAlert.scss";
import Layout from "../../layouts/Layout";
import * as PR from "../../prime-react/index";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cleanData, descriptionCount, displayStatesForSelectedCountries, fileTypesConfig, fileUploadLimit, formatNumber, getTimeZoneOptions, handleNumericInput, maxNumberOfFiles } from "../../utils/reuse";
import { RAW_FILTER_DATA } from '../common';
import { createAlert, getLocations, getSports, getTournament, getTournamentLevels } from "../../services/api";
import { useToast } from "../../context/ToastContext";
import moment from 'moment-timezone';

const CreateAlert = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { showToast } = useToast();
    const sessionData = useSelector(state => state.auth.sessionData);
    const headers = useMemo(() => {
        return { 
            sessionid: sessionData.sessionId,
            'Content-Type': 'multipart/form-data'
        };
    }, [sessionData.sessionId]);
    const isMember = useSelector((state) => state.auth.role) === "member";
    const isAnalyst = useSelector((state) => state.auth.role) === "analyst";
    const alerts = RAW_FILTER_DATA.alertTypeFilter;
    const channels = RAW_FILTER_DATA.channels;
    const betTimeOptions = RAW_FILTER_DATA.timeOfBet;
    const betsStateOptions = RAW_FILTER_DATA.stateOfBetsFilter;
    const confirmOptions = RAW_FILTER_DATA.confirmOptions;
    const alertsRaised = RAW_FILTER_DATA.alertsRaised;
    const customerTypeOptions = RAW_FILTER_DATA.customerTypes;
    const gender = RAW_FILTER_DATA.gender;

    const [timezones, setTimezones] = useState([]);

    let fileUploadRef = useRef(null);  //file ref
    const debounceTimeout = useRef(null);
    const [eventDetails, setEventDetails] = useState([]);

    const [maxBets, setMaxBets] = useState('');
    const [timeOfAlert, setAlertTime] = useState('');
    const [errorMessage,setErrorMessage] = useState("")
    const [sports, setSports] = useState([]);
    const [location, setLocation] = useState([]);
    const [tournamentLevel, setTournamentLevel] = useState([]);
    const [filteredTournaments, setFilteredTournaments] = useState([]);
    const [showError, setshowError] = useState('');
    const [alertLoading, setAlertLoading] = useState(false);
    
    const handleAdd = () => {
        const newCustomers = [...formik.values.customerDetails, { count: '', country: '' }];
        formik.setFieldValue('customerDetails', newCustomers);
    };
    const handleDelete = (index) => {
        const updatedCustomers = [...formik.values.customerDetails];
        updatedCustomers?.splice(index, 1);
        formik.setFieldValue('customerDetails', updatedCustomers);
    };
    const initialValues = {
        alertType: null,
        count: '',
        channel: [],
        stateOfBet: [],
        timeOfBet: [],
        maxBets: '',
        timeOfAlert: '',
        customerType: [],
        customerDetails: [],
        newCustomer: { count: '', country: '' },
        description: '',
        attachments: null,
    };

    const formatEventData = (eventDetails) => {
        const updatedEvents = eventDetails.map(event => {
            let data = {};
            data.eventName = event.eventName;
            data.sportName = event.sport.name;
            data.gameName = event?.gameName;
            data.sportID = event.sport.id;
            data.tournamentLevelID = event.tournament.id;
            data.tournamentLevelName = event.tournament.name;
            data.tournamentName = event.league.name;
            data.tournamentID = event.league.id;
            data.locationID = event.location.id;
            data.locationName = event.location.name;
            data.gender = event.gender;
            data.turnover = parseInt(event.turnover);

            const parsedDate = moment(event.dateTime).format('YYYY-MM-DDTHH:mm:ss');
            const dateInUTC = moment.tz(parsedDate, event.timezone).utc().format();
            data.dateTime = dateInUTC;

            return data;

        });
    
        return updatedEvents;
    };

    const formatcustomerDetails = (customers) => {
        const updatedCustomers = customers?.map(customer => {
            let data = {}
            data.count = customer.count;
            data.country = customer.country.name;
            if(customer.state){
                data.state = customer.state
            }
            return data;
        })
        return updatedCustomers;
    }

    const handleSubmit = (formValues) => {
       setAlertLoading(true);
        let obj = {};
        obj.alertType = formValues.alertType.id;
        obj.count = formValues.count;
        obj.channel = formValues.channel;
        obj.timeOfBet = formValues.timeOfBet;
        obj.stateOfBet = formValues.stateOfBet;
        obj.maxBets = formValues.maxBets;
        obj.timeOfAlert = formValues.timeOfAlert;

        const customerDetails = formatcustomerDetails([...formValues.customerDetails, formValues.newCustomer]);
        const customer = {
            customerType: formValues.customerType,
            customerDetails: customerDetails
        }
        obj.description = formValues.description.trim().replace(/[ \t]{2,}/g, ' ');
        obj.events = formatEventData(eventDetails);

        const fnalObj = {...obj, customer};
        const refinedObj = cleanData(fnalObj);

        const formData = new FormData();
        formData.append('data', JSON.stringify(refinedObj)); 
        if (formValues.attachments && formValues.attachments.length > 0) {
            formValues.attachments.forEach((file, index) => {
                formData.append(`attachments`, file);
            });
        }
        setCreateAlert(formData);
    };

    const setCreateAlert = (obj) => {
        createAlert(obj, headers, dispatch, response => {
            setAlertLoading(false);
            if (response.result === 'SUCCESS') {
                formik.resetForm();  
                setshowError('');
                showToast('success', 'Created Alert Successfully')
                const alertId = response.data;
                localStorage.setItem('pageRefreshed', false);
                setTimeout(() => {
                    navigate(`/alert-details/${alertId}`);
                }, 1000);
            } else {
                const error = response.error;
                setshowError(error?.errorMsg || error?.summary);
                showToast(error.severity, error?.errorMsg || error?.summary);
            }
        })
    }

    const validationSchema = () => {
        let schema = Yup.object().shape({
            alertType: Yup.object().nullable().required('Alert type required'),
            count: Yup.number().required('Number of events required')
                .min(1, 'Number of events must be greater than or equal to 1')
                .max(10, 'Number of events must be less than or equal to 10'),
            channel: Yup.array().when(`isAnalyst`, {
                is: () => isAnalyst === false,
                then: () => Yup.array().min(1, 'At least one channel must be selected'),
                otherwise: () => Yup.array().nullable(),
            }),
            stateOfBet: Yup.array().when(`isAnalyst`, {
                is: () => !isAnalyst,
                then: () => Yup.array().min(1, 'At least one state of bets must be selected'),
                otherwise: () => Yup.array().nullable(),
            }),
            timeOfBet: Yup.array().when(`isAnalyst`, {
                is: () => !isAnalyst,
                then: () => Yup.array().min(1, 'At least one timing of bet must be selected'),
                otherwise: () => Yup.array().nullable(),
            }),
            customerType: Yup.array().when([`newCustomer`, 'customerDetails'], {
                is: (newCustomer, customerDetails) => {
                    const customerData = [newCustomer, ...customerDetails];
                    const data = customerData.map(c => (c.country && c.count) ? true : false);
                    return !isAnalyst || data.includes(true);
                },
                then: () => Yup.array().min(1, 'At least one customer type must be selected'),
                otherwise: () => Yup.array().nullable(),
            }),
            maxBets: Yup.string().when(`isAnalyst`, {
                is: () => !isAnalyst,
                then: () => Yup.string().required('Max Bets is required'),
                otherwise: () => Yup.string().nullable(),
            }),
            timeOfAlert: Yup.string().when(`isAnalyst`, {
                is: () => !isAnalyst,
                then: () => Yup.string().required('Time of alert is required'),
                otherwise: () => Yup.string().nullable(),
            }),
            customerDetails: Yup.array().of(
                Yup.object().shape({
                    count: Yup.number().when(`isAnalyst`, {
                        is: () => !isAnalyst,
                        then: () => Yup.number().typeError('Number of customers must be a number').min(1, 'Number of customers must be at least 1').required('Number of customers is required'),
                        otherwise: () => Yup.number().optional(),
                    }),
                    country: Yup.object().when(`count`, {
                        is: (count) => { return !isAnalyst || count > 0},
                        then: () => Yup.object().required('Country required'),
                        otherwise: () => Yup.object().nullable(),
                    }) ,
                    state: Yup.string().when('country', {
                        is: (country) => {
                            return displayStatesForSelectedCountries.includes(country?.name);
                        },
                        then: () => Yup.string().required('State required'),
                        otherwise: () => Yup.string().nullable(),
                    }),
                }).test('country-count-validation', { count:'Number of customers must be at least 1'}, function (values) {
                    const { count, country } = values;
                    if (!count && country?.name) {
                      return false; // This will trigger the error message below
                    }
                    return true; // Valid if no errors
                  })
            ),
            newCustomer: Yup.object().shape({
                count: Yup.number().when(`isAnalyst`, {
                    is: () => !isAnalyst,
                    then: () => Yup.number().typeError('Number of customers must be a number').min(1, 'Number of customers must be at least 1').required('Number of customers is required'),
                    otherwise: () => Yup.number().optional(),
                }),
                country: Yup.object().when(`count`, {
                    is: (count) => { return !isAnalyst || count > 0},
                    then: () => Yup.object().required('Country required'),
                    otherwise: () => Yup.object().nullable(),
                }),
                state: Yup.string().when('country', {
                    is: (country) => {
                        return displayStatesForSelectedCountries.includes(country?.name);
                    },
                    then: () => Yup.string().required('State required'),
                    otherwise: () => Yup.string().nullable(),
                }),
            }).test('country-count-validation', { count:'Number of customers must be at least 1'}, function (values) {
                const { count, country } = values;
                if (!count && country?.name) {
                  return false; // This will trigger the error message below
                }
                return true; // Valid if no errors
            }),
            description: Yup.string().when(`isAnalyst`, {
                is: () => !isAnalyst,
                then: () => Yup.string().required('Description required').max(descriptionCount, `Description should be ${descriptionCount} characters or less.`),
                otherwise: () => Yup.string().optional(),
            }),
            attachments: Yup.array().max(maxNumberOfFiles, `You can upload a maximum of ${maxNumberOfFiles} files`).nullable().of(Yup.mixed().nullable()
                .test('fileSize', `File size must be less than ${fileUploadLimit.label}`, (value) => {
                return !value || value.size <= fileUploadLimit.value; // 1 MB in bytes
                })
                .test('fileType', 'Only PDF, DOCX or XLSX files are allowed', (value) => {
                    if (!value) return true; // If no file is provided, return true
                    const acceptedTypes = fileTypesConfig;
                    return acceptedTypes.includes(value.type);
                })
            ) 
        });

        // Dynamically add validation rules for event fields
        for (let i = 0; i < eventDetails.length; i++) {
            schema = schema.shape({
                [`eventName-${i}`]: Yup.string().required(`Event name is required`),
                [`sport-${i}`]: Yup.object().nullable().required(`Sport is required`),
                [`gameName-${i}`]: Yup.object().when([`sport-${i}`], {
                    is: (sportName) => sportName?.name.toLowerCase() === 'esports',
                    then: () => Yup.string().required('Game name is required').max(50, 'Game name can be a maximum of 50 characters'),                             
                    otherwise: () => Yup.string().nullable(),
                }),
                [`league-${i}`]: Yup.mixed().required('League is required'),
                [`tournament-${i}`]: Yup.object().when([`sport-${i}`], {
                    is: (sportName) => sportName?.name.toLowerCase() === 'tennis',
                    then: () => Yup.object().required('Tournament level is required'),                             
                    otherwise: () => Yup.object().nullable(),
                }),
                [`location-${i}`]: Yup.object().nullable().required(`Location is required`),
                [`gender-${i}`]: Yup.string().required(`Gender is required`),
                [`dateTime-${i}`]: Yup.date().required(`Date / Time is required`),
                [`timezone-${i}`]: Yup.string().required(`Timezone is required`),
                [`turnover-${i}`]: Yup.number().when(`isMember`, {
                    is: () =>  isMember,
                    then: () => Yup.number().required('Turnover is required').min(1, 'Turnover must be greater than or equal to 1'),
                    otherwise: () =>  Yup.number().nullable(),
                }),
            });
        }
        return schema;
    };

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
    });

    const handleEventDetailChange = (index, value, property) => {
        const newEvents = [...eventDetails];
        newEvents[index][property] = value;
        setEventDetails(newEvents);
        formik.setFieldValue(`${property}-${index}`, value);
        if (property === 'sport') {
            if(value.name ==='eSports'){
                formik.setFieldValue(`locationOptions-${index}`, location)
            }else{
                formik.setFieldValue(`locationOptions-${index}`, location.filter(c => c.name !=="No Country"))

            }
            newEvents[index].tournament = {};   // Reset tournament field
            newEvents[index].league = {};       // Reset tournament level field
            newEvents[index].gameName = "";      
            formik.setFieldValue(`tournament-${index}`, null);
            formik.setFieldValue(`gameName-${index}`, '');
            formik.setFieldValue(`league-${index}`, null);
            formik.setFieldValue(`tournamentLevelOptions-${index}`, [])
            setEventDetails(newEvents);

            if(value?.name.toLowerCase() === 'tennis'){
                formik.setFieldValue(`tournamentLevelOptions-${index}`, tournamentLevel)
            }
         }   
         if(property === 'league') {
            if(value?.name.trim() === '') {
                formik.setFieldValue(`league-${index}`, null);
            }  else {
                if (value.name.length > 2) {
                    if (debounceTimeout.current) {
                        clearTimeout(debounceTimeout.current);
                    }
                    debounceTimeout.current = setTimeout(() => {
                        getTournamenthandler(newEvents[index].sport.id, value.name);
                      }, 500);
                }
            }
         } 
    }
    useEffect(() => {
        getTournmamentLevelsHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const handleMultiSelect = (e, fieldName) => {
        const { value } = e.target;
        const set = new Set(formik.values[fieldName]);
        if (set.has(value)) {
            set.delete(value);
        } else {
            set.add(value);
        }
        const newValue = Array.from(set);
        formik.setFieldTouched(fieldName, true);  // Mark as touched
        formik.setFieldValue(fieldName, newValue);
    };
    

    const handleMaxBets = (value) => {
        setMaxBets(value);
        formik.setFieldValue('maxBets', value)
    }

    const handleAlertTime = (value) => {
        setAlertTime(value);
        formik.setFieldValue('timeOfAlert', value)
    }


    const handleNumOfEventsChange = (e) => {
        const count = parseInt(e.target.value);
        if (!isNaN(count)) {
            if (count >= 0 && count <= 10) {
                const existingEventsCount = eventDetails.length;
                if (count > existingEventsCount) {
                    // Add new events
                    const newEvents = [...eventDetails];
                    for (let i = existingEventsCount; i < count; i++) {
                        newEvents.push({ eventName: '', sport: null });
                    }
                    setEventDetails(newEvents);
                } else if (count < existingEventsCount) {
                    // Remove excess events
                    const newEvents = eventDetails.slice(0, count);
                    setEventDetails(newEvents);
                }
                formik.setFieldValue('count', count);
            }
        } else {
            setEventDetails([]);
        }
        formik.handleChange(e);
    };

  

     // Get list of all the sports
    const getSportsHandler = useCallback(() => {
        getSports(null, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {   
                const responseData = response.data;
                const sports = responseData;
                const result = (sports?.length > 0) ? sports?.sort((a, b) => a.name.localeCompare(b.name)) : [];
                setSports(result);
                if (result.length === 0) {
                    showToast('warn', 'No records found')
                }
            } else {
                setSports([]);
                const error = response.error;
                showToast(error.severity, error?.errorMsg || error?.summary);
            }
        })
    }, [dispatch, headers, showToast]);

     // Get list of all the locations
     const getLocationHandler = useCallback(() => {
        getLocations(headers, dispatch, response => {
            if (response.result === 'SUCCESS') {   
                const responseData = response.data;
                const locations = responseData;  
                const result = (locations?.length > 0) ? locations?.sort((a, b) => a.name.localeCompare(b.name)) : [];
                setLocation(result);
                if (result.length === 0) {
                    showToast('warn', 'No records found')
                }
            } else {
                setLocation([]);
                const error = response.error;
                showToast(error.severity, error?.errorMsg || error?.summary);
            }
        })
    }, [dispatch, headers, showToast]);

      // Get list of all the tournament levels
    const getTournmamentLevelsHandler = useCallback(() => {
        getTournamentLevels(null, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {   
                const responseData = response.data;
                const tournamentLevel = responseData;  
                const result = (tournamentLevel?.length > 0) ? tournamentLevel?.sort((a, b) => a.name.localeCompare(b.name)) : [];
                setTournamentLevel(result);
            } else {
                setTournamentLevel([]);
                const error = response.error;
                showToast(error.severity, error?.errorMsg || error?.summary);
            }
        })
    }, [dispatch, headers, showToast]);

    //  function to filter searched results of autocomplete 
    const search = (event) => {
        const searchTerm = event.query?.toLowerCase();
        return filteredTournaments.filter(tournament =>
            tournament.name?.toLowerCase().includes(searchTerm)
        );
    };
    
    
    // Get all the tournaments based on SportId
    const getTournamenthandler = useCallback((sportId, filterString) => {
        const obj = {
            sportid: sportId,
            filter: filterString.toLowerCase()
        };      
        getTournament(obj,headers, dispatch, response => {
            if (response.result === 'SUCCESS') {   
                const tournaments = response.data;
                setFilteredTournaments(tournaments);
            } else {
                setFilteredTournaments([]); 
                const error = response.error;
                showToast(error.severity, error?.errorMsg || error?.summary);
            }
        })
    }, [dispatch, headers, showToast]);

    useEffect(() => {
        getSportsHandler();
        getLocationHandler();
    },[getSportsHandler, getLocationHandler]);

    const handleDeleteFile = (index) => {
        const updatedAttachments = [...formik.values.attachments];
        updatedAttachments.splice(index, 1);
        formik.setFieldValue('attachments', updatedAttachments);
    };

    // Fetch timezones with type
    useEffect(() => {
        const fetchTimeZones = async () => {
            try {
                const tzOptions = getTimeZoneOptions();
                setTimezones(tzOptions);
            } catch (error) {
                setTimezones([]);
                console.error("Error fetching time zones:", error);
            } 
        };

        fetchTimeZones();
    }, []);

    return (
        <>
            <Layout backBtn={"home"} backBtnLabel="Home">
                <section className="create-alert-section">
                    <div className="create-alert-wrapper">
                        <div className="m-0">
                            <h1>Create Alert</h1>
                        </div>
                        {/* Create alert form section starts here */}
                        <div className="form-section">
                            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                                <div className="form-body">
                                    <div className="grid align-items-center">
                                        <div className="col-12 mb-2">
                                            <div className="flex flex-column gap-2">
                                                <label htmlFor="alertType">Type of alert<span className="important">*</span></label>
                                                <PR.Dropdown
                                                    inputId="alertType"
                                                    value={formik.values.alertType}
                                                    onChange={(e) => {
                                                        formik.setFieldValue('alertType', e.value);
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    name="alertType"
                                                    options={alerts}
                                                    optionLabel="name"
                                                    placeholder="Select type of alert"
                                                    className="w-full alert-drop-down"
                                                />
                                                {formik.errors.alertType && formik.touched.alertType && (
                                                    <div className="error-message">{formik.errors.alertType}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="flex flex-column form-input gap-2">
                                                <label htmlFor="count">Number of events<span className="important">*</span></label>
                                                <PR.InputText
                                                    id="count"
                                                    type="number"
                                                    placeholder="Number of Events"
                                                    onInput={(e) => {
                                                        const value = e.target.value;
                                                        if (parseInt(value, 10) > 10) {
                                                            e.target.value = value.slice(0,2);
                                                        } else if( parseInt(value) === 0){
                                                            e.target.value = 0;
                                                        }
                                                    }} 
                                                    onChange={handleNumOfEventsChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.count}
                                                    keyfilter={/^[0-9]*$/} 
                                                    aria-describedby="events-help"
                                                    min={0}
                                                    max={10}
                                                />
                                                {formik.errors.count && formik.touched.count && (
                                                    <div className="error-message">{formik.errors.count}</div>
                                                )}
                                            </div>
                                            {eventDetails.length > 0 && <div className="events-section">
                                                {/* Event */}
                                                {eventDetails.map((event, index) => (
                                                    <div className="grid align-items-baseline" key={index}>
                                                        <div className="custom-col">
                                                            <span className="number">{formatNumber(index + 1)}</span>
                                                        </div>
                                                        <div className="col">
                                                            <div className="flex flex-column form-input gap-2 mb-4">
                                                                <label htmlFor={`eventName-${index}`}>Event name<span className="important">*</span></label>
                                                                <PR.InputText
                                                                    id={`eventName-${index}`}
                                                                    type="text"
                                                                    aria-describedby="eventname"
                                                                    name={`eventName-${index}`}
                                                                    onBlur={formik.handleBlur}
                                                                    value={event.eventName}
                                                                    onChange={(e) => handleEventDetailChange(index, e.target.value, 'eventName')}
                                                                />
                                                                {(formik.touched[`eventName-${index}`] || formik.submitCount > 0) && formik.errors[`eventName-${index}`] && (
                                                                    <div className="error-message">{formik.errors[`eventName-${index}`]}</div>
                                                                )}
                                                            </div>
                                                            <div className="flex flex-column gap-2 mb-4">
                                                                <label htmlFor={`sport-${index}`}>Select sport<span className="important">*</span></label>
                                                                <PR.Dropdown
                                                                    inputId={`sport-${index}`}
                                                                    value={event.sport}
                                                                    name={`sport-${index}`}
                                                                    onChange={(e) => handleEventDetailChange(index, e.value, 'sport')}
                                                                    options={sports} optionLabel="name"
                                                                    placeholder="Select sport"
                                                                    onBlur={formik.handleBlur}
                                                                    filter
                                                                    resetFilterOnHide
                                                                    className="w-full alert-drop-down"
                                                                />
                                                                {(formik.touched[`sport-${index}`] || formik.submitCount > 0) && formik.errors[`sport-${index}`] && (
                                                                    <div className="error-message">{formik.errors[`sport-${index}`]}</div>
                                                                )}
                                                            </div>
                                                            {event?.sport?.name?.toLowerCase() === 'esports' &&
                                                                <div className="flex flex-column form-input gap-2 mb-4">
                                                                    <label htmlFor={`gameName-${index}`}>Game name<span className="important">*</span></label>
                                                                    <PR.InputText
                                                                        id={`gameName-${index}`}
                                                                        type="text"
                                                                        aria-describedby="gameName"
                                                                        name={`gameName-${index}`}
                                                                        onBlur={formik.handleBlur}
                                                                        value={event.gameName}
                                                                        onChange={(e) => handleEventDetailChange(index, e.target.value, 'gameName')}
                                                                    />
                                                                    {(formik.touched[`gameName-${index}`] || formik.submitCount > 0) && formik.errors[`gameName-${index}`] && (
                                                                        <div className="error-message">{formik.errors[`gameName-${index}`]}</div>
                                                                    )}
                                                                </div>
                                                            }
                                                            <div className="grid mb-4">
                                                            <div className="col-6">
                                                                    <div className="flex flex-column form-input gap-2 editAlert">
                                                                        <label htmlFor={`league-${index}`}>League / Tournament<span className="important">*</span></label>
                                                                            <PR.AutoComplete 
                                                                                inputId={`league-${index}`}
                                                                                name={`league-${index}`}
                                                                                onBlur={formik.handleBlur}
                                                                                dropdownAutoFocus={true}
                                                                                value={event.league ? event.league?.name : ''}
                                                                                onChange={(e) => {
                                                                                    if (typeof e.value === 'string') {
                                                                                        handleEventDetailChange(index, { name: e.value }, 'league'); // value is a string
                                                                                    } else if (e.value) {
                                                                                        handleEventDetailChange(index, e.value, 'league'); //value is an object
                                                                                    }
                                                                                }}
                                                                                aria-describedby="league"
                                                                                suggestions={filteredTournaments} 
                                                                                completeMethod={search} 
                                                                                itemTemplate={(tournament) => tournament.name}
                                                                                className="form-autocomplete"
                                                                                panelClassName="editAlert"
                                                                                disabled={!event.sport}
                                                                            />
                                                                        {(formik.touched[`league-${index}`] || formik.submitCount > 0) && formik.errors[`league-${index}`] && (
                                                                            <div className="error-message">{formik.errors[`league-${index}`]}</div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <div className="flex flex-column gap-2">
                                                                        <label htmlFor={`tournament-${index}`}>Tournament level {(event.sport?.name.toLowerCase() === 'tennis') && <span className="important">*</span>}</label>
                                                                        <PR.Dropdown
                                                                            inputId={`tournament-${index}`}
                                                                            value={event.tournament}
                                                                            name={`tournament-${index}`}
                                                                            onChange={(e) => handleEventDetailChange(index, e.value, 'tournament')}
                                                                            options={formik.values[`tournamentLevelOptions-${index}`]}
                                                                            optionLabel="name"
                                                                            filter
                                                                            onBlur={formik.handleBlur}
                                                                            placeholder="Select tournament level"
                                                                            className="w-full alert-drop-down"
                                                                            disabled={formik.values[`tournamentLevelOptions-${index}`]?.length <= 0}
                                                                        />
                                                                        {(formik.touched[`tournament-${index}`] || formik.submitCount > 0) && formik.errors[`tournament-${index}`] && (
                                                                            <div className="error-message">{formik.errors[`tournament-${index}`]}</div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="grid mb-4">
                                                                <div className="col-6">
                                                                    <div className="flex flex-column gap-2">
                                                                        <label htmlFor={`dateTime-${index}`}>Date / Time of event 
                                                                        <span style={{color: '#5b5858'}}> (Format: dd/mm/yyyy hh:mm)</span><span className="important">*</span>
                                                                        </label>
                                                                        <PR.Calendar
                                                                            inputId={`dateTime-${index}`}
                                                                            name={`dateTime-${index}`}
                                                                            placeholder="Select date"
                                                                            value={event.dateTime}
                                                                            onFocus={() => formik.setFieldTouched(`dateTime-${index}`)}
                                                                            onChange={(e) => handleEventDetailChange(index, e.value, 'dateTime')}
                                                                            showTime hourFormat="24"
                                                                            showIcon iconPos="right"
                                                                            className="date-calendar"
                                                                            dateFormat="dd/mm/yy"
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === 'Enter') {
                                                                                    e.preventDefault(); // Prevent form submission
                                                                                }
                                                                            }}
                                                                        />
                                                                        {(formik.touched[`dateTime-${index}`] || formik.submitCount > 0) && formik.errors[`dateTime-${index}`] && (
                                                                            <div className="error-message">{formik.errors[`dateTime-${index}`]}</div>
                                                                        )}
                                                                    </div>
                                                                    <div className="flex flex-column gap-2 mt-2">
                                                                        <PR.Dropdown
                                                                            inputId={`timezone-${index}`}
                                                                            value={event.timezone}
                                                                            name={`timezone-${index}`}
                                                                            onChange={(e) => handleEventDetailChange(index, e.value, 'timezone')}
                                                                            options={timezones}
                                                                            onBlur={formik.handleBlur}
                                                                            filter
                                                                            placeholder="Select timezone"
                                                                            className="w-full alert-drop-down"
                                                                            resetFilterOnHide
                                                                        />
                                                                        {(formik.touched[`timezone-${index}`] || formik.submitCount > 0) && formik.errors[`timezone-${index}`] && (
                                                                            <div className="error-message">{formik.errors[`timezone-${index}`]}</div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <div className="flex flex-column gap-2">
                                                                        <label htmlFor={`location-${index}`}>Location of event<span className="important">*</span></label>
                                                                        <PR.Dropdown
                                                                            inputId={`location-${index}`}
                                                                            value={event.location}
                                                                            name={`location-${index}`}
                                                                            onChange={(e) => handleEventDetailChange(index, e.value, 'location')}
                                                                            options={formik.values[`locationOptions-${index}`] || location}
                                                                            optionLabel="name"
                                                                            onBlur={formik.handleBlur}
                                                                            filter
                                                                            placeholder="Select event location"
                                                                            className="w-full alert-drop-down"
                                                                        />
                                                                        {(formik.touched[`location-${index}`] || formik.submitCount > 0) && formik.errors[`location-${index}`] && (
                                                                            <div className="error-message">{formik.errors[`location-${index}`]}</div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="grid mb-4">
                                                                <div className="col-12">
                                                                    <div className="flex flex-column gap-2">
                                                                        <span className="label">Gender<span className="important">*</span></span>
                                                                        <div className="card flex mt-1 gap-3">
                                                                            {gender.map((option) => (    
                                                                                <div className="flex flex-wrap gap-2" key={option.id}>
                                                                                    <div className="flex align-items-center">
                                                                                        <PR.RadioButton
                                                                                            inputId={`gender-${option.id}${index}`}
                                                                                            name={`gender-${index}`}
                                                                                            value={option.id}
                                                                                            onChange={(e) => handleEventDetailChange(index, e.value, 'gender')}
                                                                                            checked={event.gender === option.id}
                                                                                        />
                                                                                        <label htmlFor={`gender-${option.id}${index}`} className="ml-2">{option.name}</label>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        {(formik.touched[`gender-${index}`] || formik.submitCount > 0) && formik.errors[`gender-${index}`] && (
                                                                            <div className="error-message">{formik.errors[`gender-${index}`]}</div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="grid mb-0">
                                                                <div className="col-12">
                                                                    <div className="flex flex-column form-input gap-2">
                                                                        <label htmlFor={`turnover-${index}`}>How much did the suspicious turnover account for as a percentage of the event as a whole, if available?{isMember && <span className="important">*</span>}</label>
                                                                        <PR.InputText
                                                                            id={`turnover-${index}`}
                                                                            name={`turnover-${index}`}
                                                                            value={event.turnover || ''}
                                                                            onChange={(e) => {
                                                                                handleNumericInput(e);
                                                                                handleEventDetailChange(index, e.target.value, 'turnover')
                                                                            }}
                                                                            type="text"
                                                                            aria-describedby="turnover"
                                                                        />
                                                                        {(formik.touched[`turnover-${index}`] || formik.submitCount > 0) && formik.errors[`turnover-${index}`] && (
                                                                            <div className="error-message">{formik.errors[`turnover-${index}`]}</div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr className="mt-4 mb-4" />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>}
                                        </div>
                                        <div className="col-12 mb-3">
                                            <div className="flex flex-column gap-2">
                                                <span className="label">Channel{!isAnalyst && <span className="important">*</span>}</span>
                                                <div className="flex flex-wrap gap-3 mt-1">
                                                    {channels.map((channel) => (
                                                        <div className="flex align-items-center" key={channel.id}>
                                                            <PR.Checkbox
                                                                inputId={channel.id}
                                                                value={channel.id}
                                                                onChange={(e) => handleMultiSelect(e, 'channel')}
                                                                checked={formik.values.channel.includes(channel.id)}></PR.Checkbox>
                                                            <label htmlFor={channel.id} className="ml-2 font-medium">{channel.name}</label>
                                                        </div>
                                                    ))}
                                                </div>
                                                {formik.errors.channel && (formik.touched.channel || formik.submitCount > 0) && (
                                                    <div className="error-message">{formik.errors.channel}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <div className="flex flex-column gap-2">
                                                <span className="label">Time of bet{!isAnalyst && <span className="important">*</span>}</span>
                                                <div className="flex flex-wrap gap-3 mt-1">
                                                    {betTimeOptions.map((option) => (
                                                        <div className="flex align-items-center" key={option.id}>
                                                            <PR.Checkbox
                                                                inputId={option.id}
                                                                value={option.id}
                                                                onChange={(e) => handleMultiSelect(e, 'timeOfBet')}
                                                                checked={formik.values.timeOfBet.includes(option.id)}></PR.Checkbox>
                                                            <label htmlFor={option.id} className="ml-2 font-medium">{option.name}</label>
                                                        </div>
                                                    ))}
                                                </div>
                                                {formik.errors.timeOfBet && (formik.touched.timeOfBet || formik.submitCount > 0) && (
                                                    <div className="error-message">{formik.errors.timeOfBet}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <div className="flex flex-column gap-2">
                                                <span className="label">State of the bets{!isAnalyst && <span className="important">*</span>}</span>
                                                <div className="flex flex-wrap gap-3 mt-1">
                                                    {betsStateOptions.map((option) => (
                                                        <div className="flex align-items-center" key={option.id}>
                                                            <PR.Checkbox
                                                                inputId={option.id}
                                                                value={option.id}
                                                                onChange={(e) => handleMultiSelect(e, 'stateOfBet')}
                                                                checked={formik.values.stateOfBet.includes(option.id)}></PR.Checkbox>
                                                            <label htmlFor={option.id} className="ml-2 font-medium">{option.name}</label>
                                                        </div>
                                                    ))}
                                                </div>
                                                {formik.errors.stateOfBet && (formik.touched.stateOfBet || formik.submitCount > 0) && (
                                                    <div className="error-message">{formik.errors.stateOfBet}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="flex flex-column gap-2">
                                                <span className="label">Max bets{!isAnalyst && <span className="important">*</span>}</span>
                                                <div className="card flex mt-1 gap-3">
                                                    {confirmOptions.map((option) => (    
                                                        <div className="flex flex-wrap gap-2" key={option.id}>
                                                            <div className="flex align-items-center">
                                                                <PR.RadioButton
                                                                    inputId={`bet-yes-${option.id}`}
                                                                    name="bets"
                                                                    value={option.id}
                                                                    onChange={(e) => handleMaxBets(e.value)}
                                                                    checked={maxBets === option.id}
                                                                />
                                                                <label htmlFor={`bet-yes-${option.id}`} className="ml-2 font-medium">{option.name}</label>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                {formik.errors.maxBets && (formik.touched.maxBets || formik.submitCount > 0) && (
                                                    <div className="error-message">{formik.errors.maxBets}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="flex flex-column gap-2">
                                                <span className="label">When is the alert being raised?{isMember && <span className="important">*</span>}</span>
                                                <div className="card flex mt-1 gap-3">
                                                    {alertsRaised.map((option) => (      
                                                        <div className="flex flex-wrap gap-2" key={option.id}>
                                                            <div className="flex align-items-center">
                                                                <PR.RadioButton
                                                                    inputId={`pre-event-${option.id}`}
                                                                    name="raised"
                                                                    value={option.id}
                                                                    onChange={(e) => handleAlertTime(e.value)}
                                                                    checked={timeOfAlert === option.id}
                                                                />
                                                                <label htmlFor={`pre-event-${option.id}`} className="ml-2 font-medium">{option.name}</label>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                {formik.errors.timeOfAlert && (formik.touched.timeOfAlert || formik.submitCount > 0) && (
                                                    <div className="error-message">{formik.errors.timeOfAlert}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <p className="customer_info">CUSTOMER INFO</p>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <div className="flex flex-column gap-2">
                                                <span className="label">Type of customers{!isAnalyst && <span className="important">*</span>}</span>
                                                <div className="flex flex-wrap gap-3 mt-1">
                                                    {customerTypeOptions.map((option) => (
                                                        <div className="flex align-items-center" key={option.id}>
                                                            <PR.Checkbox
                                                                inputId={option.id}
                                                                value={option.id}
                                                                onChange={(e) => handleMultiSelect(e, 'customerType')}
                                                                checked={formik.values.customerType.includes(option.id)}></PR.Checkbox>
                                                            <label htmlFor={option.id} className="ml-2 font-medium capitalize-first">{option.name}</label>
                                                        </div>
                                                    ))}
                                                </div>
                                                {formik.errors.customerType && (formik.touched.customerType || formik.submitCount > 0) && (
                                                    <div className="error-message">{formik.errors.customerType}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="flex flex-column gap-2">
                                                <label htmlFor="newCustomerCount">Number of customers involved{!isAnalyst && <span className="important">*</span>}</label>
                                           
                                                {/* Add More Section */}
                                                <div className="grid">
                                                    <div className="col-4 form-input">
                                                        <PR.InputText
                                                            id="newCustomerCount"
                                                            type="number"
                                                            name="newCustomer.count"
                                                            value={formik.values.newCustomer?.count}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            placeholder="No. of customers"
                                                            aria-describedby="events-help"
                                                            min={0}
                                                        />
                                                        {formik.errors.newCustomer && (formik.touched.newCustomer || formik.submitCount > 0) && (
                                                            <div className="error-message mt-1">{formik.errors.newCustomer.count}</div>
                                                        )}
                                                    </div>
                                                    <div className="col-5">
                                                        <PR.Dropdown
                                                            inputId="newCustomer.country"
                                                            name="newCustomer.country"
                                                            value={formik.values.newCustomer?.country || ''}
                                                            onChange= {(e) => {
                                                                formik.setFieldValue(`newCustomer.state`, '');
                                                                formik.handleChange(e);
                                                              }}
                                                            onBlur={formik.handleBlur}
                                                            filter
                                                            resetFilterOnHide
                                                            options={location} 
                                                            optionLabel="name"
                                                            placeholder="Select country"
                                                            className="w-full alert-drop-down mb-1"
                                                        />
                                                        {formik.errors.newCustomer && (formik.touched.newCustomer || formik.submitCount > 0) && (
                                                            <div className="error-message mt-0">{formik.errors.newCustomer.country}</div>
                                                        )}
                                                         {displayStatesForSelectedCountries.includes(formik.values.newCustomer?.country?.name) && <PR.Dropdown
                                                            name="newCustomer.state"
                                                            filter
                                                            resetFilterOnHide
                                                            value={formik.values.newCustomer?.state || ''}
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            options={formik.values.newCustomer?.country.states}
                                                            placeholder="Select state"
                                                            className="w-full alert-drop-down"
                                                        />}
                                                        {(formik.errors.newCustomer && (formik.touched.newCustomer || formik.submitCount > 0)) && (
                                                            <div className="error-message mt-1">{formik.errors.newCustomer.state}</div>
                                                        )}

                                                    </div>
                                                    <div className="col-3 form-input">
                                                        <PR.Button label="Add More" type="button" icon="pi pi-plus" className="addmore-button" onClick={handleAdd} />
                                                    </div>
                                                </div>

                                                {formik.values.customerDetails?.map((customer, index) => {
                                                    return (
                                                        <div className="grid" key={index}>
                                                            <div className="col-4 form-input">
                                                                <PR.InputText
                                                                    id={`customerDetails[${index}].count`}
                                                                    name={`customerDetails[${index}].count`}
                                                                    type="number"
                                                                    value={formik.values.customerDetails[index].count}
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    placeholder="No. of customers"
                                                                    aria-describedby="events-help"
                                                                    min={0}
                                                                />
                                                                {formik.touched.customerDetails && formik.touched.customerDetails[index] && formik.errors.customerDetails && formik.errors.customerDetails[index] && formik.errors.customerDetails[index].count && (
                                                                    <div className="error-message mt-1">{formik.errors.customerDetails[index].count}</div>
                                                                )}
                                                            </div>
                                                            <div className="col-5">
                                                                <PR.Dropdown
                                                                    inputId={`customerDetails[${index}].country`}
                                                                    name={`customerDetails[${index}].country`}
                                                                    value={formik.values.customerDetails[index].country}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(`customerDetails[${index}].state`, '');
                                                                        formik.handleChange(e);
                                                                    }}
                                                                    onBlur={formik.handleBlur}
                                                                    options={location} 
                                                                    optionLabel="name"
                                                                    placeholder="Select country"
                                                                    filter
                                                                    filterInputProps={{ inputId: 'my-dropdown-search' }}  
                                                                    resetFilterOnHide
                                                                    className="w-full alert-drop-down mb-1"
                                                                />
                                                                {formik.touched.customerDetails && formik.touched.customerDetails[index] && formik.errors.customerDetails && formik.errors.customerDetails[index] && formik.errors.customerDetails[index].country && (
                                                                    <div className="error-message mt-1">{formik.errors.customerDetails[index].country}</div>
                                                                )}
                                                                  {displayStatesForSelectedCountries.includes(formik.values.customerDetails[index]?.country.name) &&
                                                                    <PR.Dropdown
                                                                    name={`customerDetails[${index}].state`}
                                                                    value={formik.values.customerDetails[index].state}
                                                                    filter
                                                                    resetFilterOnHide
                                                                    onChange={formik.handleChange}
                                                                    onBlur={formik.handleBlur}
                                                                    options={formik.values.customerDetails[index].country.states}
                                                                    placeholder="Select state"
                                                                    className="w-full alert-drop-down"
                                                                />
                                                                }
                                                                {formik.touched.customerDetails && formik.touched.customerDetails[index] && formik.errors.customerDetails && formik.errors.customerDetails[index] && formik.errors.customerDetails[index].state && (
                                                                    <div className="error-message mt-1">{formik.errors.customerDetails[index].state}</div>
                                                                )}

                                                            </div>
                                                            <div className="col-3 form-input">
                                                                <PR.Button
                                                                    label="Remove"
                                                                    type="button"
                                                                    icon="pi pi-minus"
                                                                    className="addmore-button"
                                                                    onClick={() => handleDelete(index)}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                {/* Add More Section */}
                                            </div>
                                        </div>
                                        <div className="col-12 mb-2">
                                            <div className="flex flex-column gap-2">
                                                <label htmlFor="description">Description of suspicious event{!isAnalyst && <span className="important">*</span>}</label>
                                                <div className="card flex justify-content-center">
                                                    <PR.InputTextarea
                                                        id="description"
                                                        name="description"
                                                        value={formik.values.description}
                                                        onChange={(e) => {
                                                            const newValue = e.target.value;
                                                            if (newValue.length > descriptionCount) {
                                                                formik.setFieldValue('description', newValue.substring(0, descriptionCount));
                                                                setErrorMessage(`Message should be ${descriptionCount} characters or less.`)
                                                            } else {
                                                                formik.setFieldValue('description', newValue);
                                                            }
                                                        }}
                                                        onPaste={(e) => {
                                                            e.preventDefault();
                                                            const pastedData = e.clipboardData.getData('text');
                                                            const input = e.target;
                                                            const { selectionStart, selectionEnd, value } = input;

                                                            // Construct the new value with pasted text at the cursor position
                                                            const newDescription = value.slice(0, selectionStart) + pastedData + value.slice(selectionEnd);

                                                            if (newDescription.length > descriptionCount) {
                                                                formik.setFieldValue('description', newDescription.substring(0, descriptionCount));
                                                                setErrorMessage(`Message should be ${descriptionCount} characters or less.`);
                                                            } else {
                                                                formik.setFieldValue('description', newDescription);
                                                            }

                                                            // Set the cursor position after the pasted text
                                                            setTimeout(() => {
                                                                input.setSelectionRange(selectionStart + pastedData.length, selectionStart + pastedData.length);
                                                            }, 0);
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        rows={8} cols={30}
                                                        className="description-textarea"
                                                    />

                                                </div>
                                                <span style={{ textAlign: "left" }}>
                                                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                                                </span>
                                                {formik.errors.description && (formik.touched.description || formik.submitCount > 0) && (
                                                    <div className="error-message">{formik.errors.description}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-8">
                                            <div className="flex flex-column gap-2">
                                                <span className="label">Attach file</span>
                                                <div className="card file-upload flex align-items-center">
                                                    <PR.FileUpload
                                                        auto
                                                        multiple
                                                        mode="advanced"
                                                        name="attachments"
                                                        accept=".pdf,.docx,.xlsx"
                                                        chooseLabel="Choose File"
                                                        uploadLabel="Upload"
                                                        emptyTemplate={null}
                                                        itemTemplate={null}
                                                        ref={fileUploadRef}
                                                        onSelect={(event) => {
                                                            if (fileUploadRef.current) {
                                                                fileUploadRef.current.clear();
                                                                setshowError('');
                                                            }
                                                            const currentAttachments = formik.values.attachments || [];
                                                            const newAttachments = event.files;
                                                        
                                                            const duplicateFiles = newAttachments?.filter((newFile) =>
                                                                currentAttachments?.some(
                                                                    (existingFile) => existingFile.name === newFile.name
                                                                )
                                                            );
                                                        
                                                            if (duplicateFiles.length > 0) {
                                                                setshowError('File names must be unique. Please rename your file(s) before uploading.');
                                                                fileUploadRef.current.clear();
                                                                return;
                                                            }
                                                        
                                                            if (currentAttachments?.length + newAttachments?.length > maxNumberOfFiles) {
                                                                setshowError(`You can upload a maximum of ${maxNumberOfFiles} files.`)
                                                                fileUploadRef.current.clear();
                                                                return;
                                                            }
                                                            const validFiles = newAttachments?.filter((file) => {
                                                                if (file.size > fileUploadLimit.value) {
                                                                    setshowError(`${file.name} is larger than ${fileUploadLimit.label}.`)
                                                                    return false;
                                                                }
                                                                return true;
                                                            });
                                                        
                                                            if (validFiles?.length !== newAttachments?.length) {
                                                                fileUploadRef.current.clear();
                                                            }
                                                        
                                                            const updatedAttachments = [...currentAttachments, ...validFiles];
                                                            formik.setFieldValue('attachments', updatedAttachments);
                                                        }}
                                                        
                                                        onFocus={() => formik.setFieldTouched(`attachments`)}
                                                        customUpload
                                                    />
                                                    <span className="ml-2"> <span className="block">(PDF, DOCX, XLSX)</span>File should be less than {fileUploadLimit.label}.</span>
                                                </div>

                                                {formik.values.attachments && (
                                                    <div className="uploaded-file-container flex flex-column gap-2">
                                                    {formik.values.attachments?.map((attachment, index) => (
                                                        <div className="uploaded-files" key={index}>
                                                            {attachment.name}
                                                            <i className="pi pi-trash" onClick={() => handleDeleteFile(index)}/>
                                                        </div>
                                                        )
                                                        )}
                                                    </div>
                                                )}
                                                {formik.errors.attachments && (formik.touched.attachments || formik.submitCount > 0) && (
                                                  <div className="error-message">
                                                     {Array.isArray(formik.errors.attachments) ? (
                                                        Array.from(new Set(formik.errors?.attachments)).map((error, index) => (
                                                            <div key={index}>{error}</div>
                                                        ))
                                                    ) : (
                                                        <div>{formik.errors.attachments}</div>
                                                    )}
                                                </div>
                                            )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="error-message">{showError}</div>
                                    <div className="form-footer mt-2">
                                        <PR.Button
                                            label="Create Alert"
                                            className="create-alert-button"
                                            type="submit"
                                            loading={alertLoading}
                                            disabled={alertLoading}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        {/* Create alert form section ends here */}
                    </div>
                </section>
            </Layout>
        </>
    );
};

export default CreateAlert;