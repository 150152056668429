import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import "./News.scss";
import Layout from "../../layouts/Layout";
import MenuBar from "../../layouts/MenuBar";
import * as PR from "../../prime-react/index";
import { websiteIcon, Avatar } from "../../assets/images/index";
import { getNews, getFileDetails } from "../../services/api";
import { sortByCreatedAt } from "../../utils/reuse";
import { useToast } from "../../context/ToastContext";
import NewsGrid from "./NewGrid";

const NewsLayout = () => {
    const { showToast } = useToast();
    const navigate = useNavigate();
    const isMember = useSelector(state => state.auth.role) === 'member';
    const isManagedMember = useSelector(state => state.auth.role) === 'managedmember';
    const dispatch = useDispatch();
    const sessionData = useSelector(state => state.auth.sessionData);
    const headers = useMemo(() => ({ sessionid: sessionData.sessionId }), [sessionData.sessionId]);
    const [images, setImages] = useState([]);
    const [isTransparent, setIsTransparent] = useState(false);
  
    const responsiveOptions = [
        {
            breakpoint: "991px",
            numVisible: 1,
        },
        {
            breakpoint: "767px",
            numVisible: 1,
        },
        {
            breakpoint: "575px",
            numVisible: 1,
        },
    ];
    const getFeaturedNewsList = useCallback(() => {
        const obj = {
            offset: 0,
            limit: 5,
            featured: true,
        };
        getNews(obj, headers, dispatch, async (response) => {
            if (response.result === 'SUCCESS') {
                const responseData = response.data?.length ? sortByCreatedAt(response.data) : [];
                const imagesPromises = responseData?.map(news => {
                    return new Promise((resolve, _) => {
                        if (!news?.fileAttachments) {
                            return resolve({
                                src: Avatar,
                                alt: news.title,
                                category: news.categoryName,
                                title: news.title,
                                description: news.description,
                                id: news.id
                            });
                        }
    
                        const attachments = JSON.parse(news.fileAttachments);
                        const validImageAttachments = attachments?.filter(attachment => {
                            return attachment?.attachmentType === "coverImage"
                        });
    
                        if (validImageAttachments?.length === 0) {
                            return resolve({
                                src: Avatar,
                                alt: news.title,
                                category: news.categoryName,
                                title: news.title,
                                description: news.description,
                                id: news.id
                            });
                        }
    
                        const imagePromises = validImageAttachments?.map(attachment =>
                            new Promise((resolve, reject) => {
                                getFileDetails(attachment.key, headers, dispatch, fileResponse => {
                                    if (fileResponse.result === 'SUCCESS') {
                                        resolve({
                                            src: URL.createObjectURL(fileResponse.data),
                                            alt: news.title,
                                            category: news.categoryName,
                                            title: news.title,
                                            description: news.description,
                                            id: news.id
                                        });
                                    } else {
                                        reject(new Error('Failed to fetch image'));
                                    }
                                });
                            })
                        );
    
                        Promise.any(imagePromises)
                            .then(image => resolve(image))
                            .catch(() => resolve({
                                src: Avatar,
                                alt: news.title,
                                category: news?.categoryName,
                                title: news.title,
                                description: news.description,
                                id: news.id
                            }));
                    });
                });
    
                try {
                    const results = await Promise.all(imagesPromises);
                    setImages(results);
                } catch (error) {
                    showToast(error.severity, error?.errorMsg || error?.summary);
                } finally {
                }
            } else {
                const error = response.error;
                showToast(error.severity, error?.errorMsg || error?.summary);
            }
        });
    }, [dispatch, headers, showToast]);

    
    useEffect(() => {
        getFeaturedNewsList();
    }, [getFeaturedNewsList]);

  
    const checkTransparency = (event) => {
        const img = event?.target;
        if (!img || img.naturalWidth === 0 || img.naturalHeight === 0) {
            console.warn('Image could not be loaded.');
            return;
        }
        const canvas = document.createElement('canvas');
        const ctx = canvas?.getContext('2d');

        // Set canvas dimensions to image dimensions
        canvas.width = img?.width;
        canvas.height = img?.height;

        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0, img?.width, img?.height);

        // Get image data
        const imageData = ctx.getImageData(0, 0, img?.width, img?.height);
        const { data } = imageData;

        // Check for any transparent pixels
        for (let i = 3; i < data.length; i += 4) {
            if (data[i] < 255) { // Alpha value less than 255 means transparency
                setIsTransparent(true);
                return;
            }
        }
        setIsTransparent(false);
    };
    const itemTemplate = (item) => {
        return (
            <img
                src={item?.src}
                alt={item?.alt}
                style={{ width: "100%", display: "block", height: "50%" }}
                onClick={() => navigate(`/news-details?id=${item?.id}`)}
                onLoad={checkTransparency } 
                className={`${isTransparent ? 'containImg': 'coverImage'}`} 

            />
        );
    };
    const caption = (item) => {
        return (
            <>
                {item?.category && <span className="category">{item?.category}</span>}
                <div className="title">{item?.title}</div>
                <p
                    className="description"
                    dangerouslySetInnerHTML={{
                        __html: item?.description?.split(" ").slice(0, 15).join(" "),
                    }}
                ></p>
            </>
        );
    };
  

    return (
        <>
            <Layout>
                <MenuBar />
                <section className="news-tab">
                    <div className="grid">
                        <div className="col-12">
                            <div className="slider-wrapper">
                                { (
                                    images.length > 0 && 
                                    <>
                                    <span className="type">Featured</span>
                                    <PR.Galleria
                                        value={images}
                                        responsiveOptions={responsiveOptions}
                                        numVisible={1}
                                        item={itemTemplate}
                                        caption={caption}
                                        className="news-slider"
                                    />
                                    </>
                                )}
                            </div>

                            {(isMember || isManagedMember) && (
                                <div className="updates-section">
                                    <div className="grid align-items-center">
                                        <div className="col-9">
                                            <div className="flex gap-3 align-items-center">
                                                <div className="icon-wrapper">
                                                    <PR.Image
                                                        src={websiteIcon}
                                                        alt="website Icon"
                                                        className="icon"
                                                    />
                                                </div>
                                                <div>
                                                    <h2>Sporting Sanctions Updates!</h2>
                                                    <p>
                                                        Stay informed and discover sanctions imposed on
                                                        individuals, teams, or organizations for rule
                                                        violations, fair play breaches, and other actions.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-3 text-right">
                                            <Link to={"/sporting-sanction"}>
                                                <PR.Button
                                                    icon="pi pi-chevron-right"
                                                    label="See All Sanctions"
                                                    iconPos="right"
                                                    className="see-all-button"
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="hr-line mt-5 mb-5"></div>
                        </div>
                        <NewsGrid title={'News & Updates'}  upcomingWeekNews={true}></NewsGrid>
                    </div>
                </section>
            </Layout>
        </>
    );

};

export default NewsLayout;
