import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import * as PR from "../../prime-react/index";
import { useToast } from "../../context/ToastContext";
import { pagecount, queryLimit, sortByCreatedAt } from "../../utils/reuse";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getNews } from "../../services/api";
import NewsItem from "./NewsItem";
import TopCategories from "../TopCategories/TopCategories";
import MostViewed from "../MostViewed/MostViewed";
import { Link, useNavigate } from "react-router-dom";
import { slide1, slide2, news1, news2, news3, news4, news5, news6, news7, upcomingNews, newView1, newView2, newView3, newView4, newView5, newView6, Avatar } from "../../assets/images/index";
import dbData from "../../assets/data/db.json";

const NewsGrid = ({ type, title, upcomingWeekNews }) => {
    const { showToast } = useToast();
    const pageCount = pagecount;
    const navigate = useNavigate();
    const isMember = useSelector(state => state.auth.role) === 'member';
    const dispatch = useDispatch();
    const sessionData = useSelector(state => state.auth.sessionData);
    const headers = useMemo(() => ({ sessionid: sessionData.sessionId }), [sessionData.sessionId]);
    const [dates, setDates] = useState(null);
    const [newsData, setNewsData] = useState([]);
    const [paginatedNewsData, setPaginatedNewsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [error, setError] = useState(null)
    const searchedNews = useRef('');
    const trigger = useRef(null);
    const errorRef = useRef(null);
    const [mostViewedData] = useState(dbData.mostViewed);
    const [upcomingIntegrityPreview] = useState(dbData.upcomingIntegrityPreview);
    const imageMap = { news1, news2, news3, news4, news5, news6, news7, upcomingNews, newView1, newView2, newView3, newView4, newView5, newView6, slide1, slide2, };
    const fetchNews = useCallback((page = 1, query = '', dateRange = null) => {
        setLoading(true);
        const obj = {
            offset: (page - 1) * pageCount,
            limit: pageCount + 1,
        };
        if (type) {
            obj.type = type
        }
        if (query) {
            obj.search = query;
        }
        if (dateRange && dateRange?.length > 0) {
            const startDate = moment(dateRange[0]).format('YYYY-MM-DD');
            const endDate = dateRange[1] ? moment(dateRange[1]).format('YYYY-MM-DD') : startDate;
            obj.startDate = startDate;
            obj.endDate = endDate;
        }
        getNews(obj, headers, dispatch, (response) => {
            if (response.result === 'SUCCESS') {
                const responseData = response.data?.length ? sortByCreatedAt(response.data) : [];
                const data = responseData?.slice(0, pageCount);
                setNewsData(responseData);
                setPaginatedNewsData(data)
            } else {
                setNewsData([]);
                setPaginatedNewsData([])
                const error = response.error;
                showToast(error.severity, error?.errorMsg || error?.summary);
            }
            setLoading(false);
        });
    }, [pageCount, type, headers, dispatch, showToast]);

    useEffect(() => {
        fetchNews(1);
    }, [fetchNews]);
    const clearError = () => {
        errorRef.current = null;
        setError(null);
    };
    const setTemporaryError = (message) => {
        errorRef.current = message;
        setError(message);
        setTimeout(() => {
            if (errorRef.current === message) {
                clearError();
            }
        }, 2500);
    };
    const searchHandler = (e) => {
        setCurrentPage(1);
        const query = e.target.value;
        setSearchQuery(query);
        if (query.length < 3) {
            if (searchedNews.current !== '') {
                searchedNews.current = '';
                if (trigger.current) clearTimeout(trigger.current);
                trigger.current = setTimeout(() => fetchNews(1, '', dates), 1000);
            }
            setTemporaryError('Please enter at least 3 characters');
            return;
        }

        if (query.length > queryLimit) {
            setTemporaryError(`Search can be at most ${queryLimit} characters long`);
            return;
        }

        if (searchedNews.current === query) {
            return; // Prevent redundant refetch
        }

        searchedNews.current = query;
        if (trigger.current) clearTimeout(trigger.current);
        trigger.current = setTimeout(() => {
            fetchNews(1, query, dates)
        }, 1000);
    };
    const dateRangeHandler = (e) => {
        setDates(e.value);
        setCurrentPage(1);
        if (e.value && !e.value?.includes(null)) {
            fetchNews(1, searchQuery, e.value)
        } else if (e.value === null) {
            fetchNews(1, searchQuery, e.value)
        }
    }
    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchNews(page, searchQuery, dates)
    };
    const navigateHandler = (categoryId, categoryName) => {
        const formattedCategory = categoryName?.replace(/ /g, '-')?.replace(/-+$/, '');
        navigate(`/news-category?categoryID=${categoryId}&categoryName=${formattedCategory}`);
    };
    return (
        <>
            <div className="col-12">
                <div className="new-list-section relative">
                    <div className="flex align-items-center justify-content-between">
                        <div className="left">
                            <h1>{title}</h1>
                        </div>
                        <div className="right flex gap-3">
                            <div className="card">
                                <div className="flex align-items-center search-bar p-input-icon-right">
                                    <span className="p-input-icon-left w-12">
                                        <i className="pi pi-search" />
                                        <PR.InputText
                                            placeholder="Search.."
                                            className="search-box w-12"
                                            value={searchQuery}
                                            onChange={searchHandler} />
                                    </span>
                                    <span className="right-clear-icon">
                                        {!!searchQuery && <i style={{ cursor: 'pointer', color: "#6c757d" }} onClick={() => searchHandler({ target: { value: "" } })} className="pi pi-times text-sm mr-2" />}
                                    </span>
                                </div>
                                {error && <p className="error-message mt-2">{error}</p>}
                            </div>
                            <div className="card custom-clear-calendar" style={{ position: 'relative' }}>
                                <PR.Calendar
                                    value={dates}
                                    onChange={dateRangeHandler}
                                    iconPos="left"
                                    readOnlyInput
                                    showIcon
                                    selectionMode="range"
                                    className="date-calendar"
                                    placeholder="Select Date"
                                    showButtonBar
                                    dateFormat="dd/mm/yy"
                                    maxDate={new Date()} />
                                {dates && (
                                    <div className='custom-clear'>
                                        <i className="pi pi-times text-sm" onClick={() => dateRangeHandler({ value: null })}></i>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="list-wrapper">
                        {loading ? (
                            <div className="loading">
                                <i className="pi pi-spin pi-spinner"></i>
                            </div>
                        ) : paginatedNewsData?.length > 0 ?
                            (
                                paginatedNewsData?.map((news) => (
                                    <React.Fragment key={news.id}>
                                        <NewsItem news={news} Avatar={Avatar} newsType={news.newsType} />
                                        <div className="hr-line mt-4 mb-4"></div>
                                    </React.Fragment>
                                ))
                            ) : (
                                <div className="flex justify-content-center">
                                    <p className="no-data m-0 text-bluegray-500">
                                        No records found
                                    </p>
                                </div>
                            )}
                    </div>

                    {/* Pagination Section */}
                    <div className="pagination">
                        <div className="grid grid-nogutter align-items-center justify-content-between">
                            <span>Page {currentPage}</span>
                            <ul className="flex list-none align-items-center gap-3">
                                <li className={currentPage === 1 ? "disabled" : ""} onClick={() => handlePageChange(currentPage - 1)}>
                                    Previous
                                </li>
                                <li className={(newsData?.length <= pageCount) ? "disabled" : ""} onClick={() => handlePageChange(currentPage + 1)}>
                                    Next
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-3" style={{ display: 'none' }}>
                {(isMember && upcomingWeekNews) && (
                    <Link to={"/weekly-integrity"}>
                        <div className="news-right-section">
                            <div className="header-section">
                                <h3>
                                    <i className="pi pi-book"></i> Upcoming week's integrity
                                    preview
                                </h3>
                            </div>
                            <div className="upcoming-news">
                                <PR.Image
                                    src={upcomingNews}
                                    alt="Upcoming Matches"
                                    className="news-img"
                                />
                                <h4>Upcoming Matches</h4>
                                {upcomingIntegrityPreview?.upcomingMatches?.map(
                                    (match, index) => (
                                        <p key={index}>{match}</p>
                                    )
                                )}
                                <div className="hr-line mt-3 mb-3"></div>
                                <h4>Players' Integrity</h4>
                                <ul>
                                    {upcomingIntegrityPreview?.playersIntegrity?.map(
                                        (integrity, index) => (
                                            <li key={index}>{integrity}</li>
                                        )
                                    )}
                                </ul>
                                <div className="hr-line mt-3 mb-3"></div>
                                <h4>Match Officials' Conduct</h4>
                                {upcomingIntegrityPreview?.matchOfficialsConduct?.map(
                                    (conduct, index) => (
                                        <p key={index}>{conduct}</p>
                                    )
                                )}
                            </div>
                        </div>
                    </Link>
                )}
                <TopCategories
                    heading={"Top Categories"}
                    handleCategoryClick={navigateHandler}
                />
                <MostViewed mostViewedData={mostViewedData} imageMap={imageMap} />
            </div>
        </>
    );
}

export default NewsGrid;