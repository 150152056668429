import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import * as PR from "../../../prime-react/index";
import "./MyAnalytics.scss";
import Layout from '../../../layouts/Layout';
import MenuBar from '../../../layouts/MenuBar';
import { useDispatch, useSelector } from 'react-redux';
import { getAlertsCount, getLocations, getSports } from '../../../services/api';
import moment from 'moment';
import { authActions } from '../../../store/auth';
import { Link, useNavigate } from 'react-router-dom';
import { mkConfig, generateCsv, download } from "export-to-csv";
import { jsPDF } from "jspdf";
import { exportFile, formatResponseTypeLabel, optionsforBarGraphs, optionsforBarGraphsWithPercentage, optionsforResponseType } from '../../../utils/reuse';

const MyAnalytics = () => {
    const sessionData = useSelector(state => state.auth.sessionData);
    const headers = useMemo(() => {
        return { sessionid: sessionData.sessionId };
    }, [sessionData.sessionId]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isExport = useSelector(state => state.auth?.exportPermission);

    const [type, setType] = useState();

    const menuRight = useRef(null);
    const [visible, setVisible] = useState(false);
    const [exportType, setExportType] = useState(null);

    const [dates, setDates] = useState([
        new Date(new Date().getFullYear(), new Date().getMonth(), 1), 
        new Date()
    ]);

    const [keyMetrics, setKeyMetrics] = useState(null);
    const [sports, setSports] = useState([]);
    const [location, setLocation] = useState([]);

    const [responseType, setResponseType] = useState(null);
    const [sportsForResponseType, setSportsForResponseType] = useState(null);
    const [responseTypeChartData, setResponseTypeChartData] = useState({});
    const defaultDataForResponseType = [
        { label: 'suspicious', color: '#B9BC19', value: 0 },
        { label: 'notsuspicious', color: '#FC702E', value: 0 },
        { label: 'notoffered', color: '#94C11F', value: 0 }
    ];
    const [chartOptions, setChartOptions] = useState(optionsforBarGraphs);
    const [chartOptionsWithPerc, setChartOptionsWithPerc] = useState(optionsforBarGraphsWithPercentage);

    const emptyData = {
        labels: null,
        datasets: [
            {
                label: '',
                data: null,
                backgroundColor: '#3067C2',
                borderRadius: 5,
                barThickness: 25,
            },
        ]
    };

    const processData = (data, setDataFunction, isTags, isSecBar) => {
        if (data) {
            const filteredData = data?.filter(item => {
                const value = isTags ? item?.tagCount : item?.alertCount || item?.responseRate || 0;
                return value > 0;
            });

            const labels = filteredData?.map(item => 
                isTags ? `${item?.tagName}` : `${item?.sportName || item?.locationName}`
            );
            const dataValues = filteredData?.map(item => 
                isTags ? `${item?.tagCount}` : `${item?.alertCount || item?.responseRate || 0}`
            );
            const chartData = {
                labels: labels,
                datasets: [
                    {
                        data: dataValues,
                        backgroundColor: isSecBar ? '#FC702E' : '#B9BC19' ,
                        borderRadius: 5,
                        barThickness: 25,
                    },
                ]
            };
            setDataFunction(chartData);
            setChartOptions(optionsforBarGraphs);
            setChartOptionsWithPerc(optionsforBarGraphsWithPercentage);    
        } else {
            setDataFunction(emptyData);
        }
    };


    const [alertRaisedBySportsChart, setAlertRaisedBySportsChart] = useState({});
    const [alertRaisedBySportsSelection, setAlertRaisedBySportsSelection] = useState(null);

    const [responseRatesBySportsChart, setResponseRatesBySportsChart] = useState({});
    const [responseRatesBySportsSelection, setResponseRatesBySportsSelection] = useState(null);

    const [totalAlertsBySportsChart, setTotalAlertsBySportsChart] = useState({});
    const [totalAlertsBySportsSelection, setTotalAlertsBySportsSelection] = useState(null);

    const [totalAlertsByLocationChart, setTotalAlertsByLocationChart] = useState({});
    const [totalAlertsByLocationSelection, setTotalAlertsByLocationSelection] = useState(null);

    const [tennisPlayersByTagsChart, setTennisPlayersByTagsChart] = useState({});

    const [chartOptionsResponseType, setChartOptionsResponseType ] = useState(optionsforResponseType);
    // saved datas 
    const [alertRaisedData, setAlertRaisedData] = useState();
    const [responseRatesData, setResponseRatesData] = useState();
    const [responseTypesData, setResponseTypesData] = useState();
    const [totalAlertsData, setTotalAlertsData] = useState();
    const [alertsByLocationData, setAlertsByLocationData] = useState();
    const [tennisPlayersData, setTennisPlayersData] = useState();
   
    //get key metric data
    const getMetricHandler = useCallback(() => {
        const obj = {
            type: "rrate,ar,art",
        }; 
        if (dates && dates?.length > 0) {
            const startDate= moment(dates[0]).format('YYYY-MM-DD');
            const endDate= dates[1] ? moment(dates[1]).format('YYYY-MM-DD') : startDate;
            obj.startDate = startDate;
            obj.endDate = endDate;
        }
        getAlertsCount(obj, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {   
                const responseData = response.data;
                setKeyMetrics(responseData);
            }
        })
    }, [dispatch, headers, dates]);

    // Get list of all the sports
    const getSportsHandler = useCallback(() => {
        getSports(null, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {   
                const responseData = response.data;
                const sports = responseData;
                const result = (sports?.length > 0) ? sports?.sort((a, b) => a.name.localeCompare(b.name)) : [];
                setSports(result);
            } else {
                setSports([]);
            }
        })
    }, [dispatch, headers]);

    // Get list of all the locations
    const getLocationHandler = useCallback(() => {
        getLocations(headers, dispatch, response => {
            if (response.result === 'SUCCESS') {   
                const responseData = response.data;
                    const locations = responseData;  
                    const result = (locations?.length > 0) ? locations?.sort((a, b) => a.name.localeCompare(b.name)) : [];
                    setLocation(result);
            } else {
                    setLocation([]);
                }
        })
    }, [dispatch, headers]);    
    
    // get response type with sports filters 
    const getResponseTypeWithSportFilter = useCallback((resSports) => {        
        const obj = {
            type: "rtps",
        }; 
        if(resSports) {
            obj.sport = resSports?.id
        }
        if (dates && dates?.length > 0) {
            const startDate= moment(dates[0]).format('YYYY-MM-DD');
            const endDate= dates[1] ? moment(dates[1]).format('YYYY-MM-DD') : startDate;
            obj.startDate = startDate;
            obj.endDate = endDate;
        }
        getAlertsCount(obj, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {   
                const responseData = response.data;
                setResponseType(responseData);
                setResponseTypesData(responseData?.responseTypePerSport);
                const analyticsData = {
                    type: 'rtps',
                    selectedDate: dates,
                    selectedData: resSports,
                    data: responseData?.responseTypePerSport
                 };
                 dispatch(authActions.updateResponseTypeAnalytics(analyticsData));

                const updatedData = response?.data?.responseTypePerSport?.map((item, index) => ({
                    label: item?.responseType || ``,
                    color: defaultDataForResponseType[index]?.color || '#000000',
                    value: item?.responseRate || 0
                })) || defaultDataForResponseType;
    
                const data = {
                    labels: updatedData.map(item => 'Response Type'),
                    datasets: updatedData.map(item => ({
                        label: item.label,
                        data: [item.value],
                        backgroundColor: item.color,
                        borderRadius: 5,
                        barThickness: 30,
                        borderWidth: 0,
                        borderColor: "#fff"
                    }))
                };
                setResponseTypeChartData(data);
                setChartOptionsResponseType(optionsforResponseType);

            }
        })
        // eslint-disable-next-line
    }, [dispatch, headers, dates]);

    //alertsRaisedBySport
    const alertsRaisedBySport = useCallback((selectedSports) => {
        const obj = {
            type: "arps",
        }; 
        if(selectedSports?.length > 0)
        {
            obj.sport = selectedSports?.map(sport => sport.id).join(',');
        }
        if (dates && dates?.length > 0) {
            const startDate= moment(dates[0]).format('YYYY-MM-DD');
            const endDate= dates[1] ? moment(dates[1]).format('YYYY-MM-DD') : startDate;
            obj.startDate = startDate;
            obj.endDate = endDate;
        }
        getAlertsCount(obj, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {   
                const responseData = response.data;
                setAlertRaisedData(responseData?.alertsRaisedPerSport);
                processData(responseData?.alertsRaisedPerSport, setAlertRaisedBySportsChart);
                const analyticsData = {
                   type: 'arps',
                   selectedDate: dates,
                   selectedData: selectedSports,
                   data: responseData?.alertsRaisedPerSport
                };
                dispatch(authActions.updateAlertRaisedAnalytics(analyticsData));
            }
        })
    // eslint-disable-next-line
    }, [dispatch, headers, dates]);

        //responseRateBySport
        const responseRatesBySport = useCallback((selectedSports) => {
            const obj = {
                type: "rrps",
            }; 
            if(selectedSports?.length > 0)
            {
                obj.sport = selectedSports?.map(sport => sport.id).join(',');
            }
            if (dates && dates?.length > 0) {
                const startDate= moment(dates[0]).format('YYYY-MM-DD');
                const endDate= dates[1] ? moment(dates[1]).format('YYYY-MM-DD') : startDate;
                obj.startDate = startDate;
                obj.endDate = endDate;
            }
            getAlertsCount(obj, headers, dispatch, response => {
                if (response.result === 'SUCCESS') {   
                    const responseData = response.data;
                    const filteredData = responseData?.responseRatePerSport?.filter(sport => sport.responseRate !== undefined);
                    setResponseRatesData(filteredData?.length > 0 ? filteredData : null);
                    processData(responseData?.responseRatePerSport, setResponseRatesBySportsChart);
                    const analyticsData = {
                        type: 'rrps',
                        selectedDate: dates,
                        selectedData: selectedSports,
                        data: responseData?.responseRatePerSport
                     };
                     dispatch(authActions.updateResponseRateAnalytics(analyticsData));
                }
            })
        // eslint-disable-next-line
        }, [dispatch, headers, dates]);

        //totalAlertsBySport
    const totalAlertsBySport = useCallback((selectedSports) => {
        const obj = {
            type: "taps",
        }; 
        if(selectedSports?.length > 0)
        {
            obj.sport = selectedSports?.map(sport => sport.id).join(',');
        }
        if (dates && dates?.length > 0) {
            const startDate= moment(dates[0]).format('YYYY-MM-DD');
            const endDate= dates[1] ? moment(dates[1]).format('YYYY-MM-DD') : startDate;
            obj.startDate = startDate;
            obj.endDate = endDate;
        }
        getAlertsCount(obj, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {   
                const responseData = response.data;
                setTotalAlertsData(responseData?.totalAlertsPerSport);
                processData(responseData?.totalAlertsPerSport, setTotalAlertsBySportsChart, false, true);
                const analyticsData = {
                    type: 'taps',
                    selectedDate: dates,
                    selectedData: selectedSports,
                    data: responseData?.totalAlertsPerSport
                 };
                 dispatch(authActions.updateTotalAlertsAnalytics(analyticsData));
            }
        })
    // eslint-disable-next-line
    }, [dispatch, headers, dates]);

     //totalAlertsByLocation
     const totalAlertsByLocation = useCallback((selectedLocation) => {
        const obj = {
            type: "tapl",
        }; 
        if(selectedLocation?.length > 0)
        {
            obj.locations  = selectedLocation?.map(location  => location.id).join(',');
        }
        if (dates && dates?.length > 0) {
            const startDate= moment(dates[0]).format('YYYY-MM-DD');
            const endDate= dates[1] ? moment(dates[1]).format('YYYY-MM-DD') : startDate;
            obj.startDate = startDate;
            obj.endDate = endDate;
        }
        getAlertsCount(obj, headers, dispatch, response => {
            if (response.result === 'SUCCESS') {   
                const responseData = response.data;
                setAlertsByLocationData(responseData?.totalAlertsPerLocation);
                processData(responseData?.totalAlertsPerLocation, setTotalAlertsByLocationChart, false, true);
                const analyticsData = {
                    type: 'tapl',
                    selectedDate: dates,
                    selectedData: selectedLocation,
                    data: responseData?.totalAlertsPerLocation
                 };
                 dispatch(authActions.updateAlertByLocationAnalytics(analyticsData));
            }
        })
    // eslint-disable-next-line
    }, [dispatch, headers, dates]);  
    
    // tagsplayersData 
        const tagsplayersData = useCallback(() => {
            const obj = {
                type: "tpc",
            }; 
            if (dates && dates?.length > 0) {
                const startDate= moment(dates[0]).format('YYYY-MM-DD');
                const endDate= dates[1] ? moment(dates[1]).format('YYYY-MM-DD') : startDate;
                obj.startDate = startDate;
                obj.endDate = endDate;
            }
            getAlertsCount(obj, headers, dispatch, response => {
                if (response.result === 'SUCCESS') {   
                    const responseData = response.data;
                    setTennisPlayersData(responseData?.tagsCount);
                    processData(responseData?.tagsCount, setTennisPlayersByTagsChart, true, true);
                    const analyticsData = {
                        type: 'tpc',
                        selectedDate: dates,
                        selectedData: null,
                        data: responseData?.tagsCount
                     };
                     dispatch(authActions.updateTagsCountAnalytics(analyticsData));
                }
            })
        // eslint-disable-next-line
        }, [dispatch, headers, dates]);
    

    useEffect(() => {
        getMetricHandler();
        getSportsHandler();
        getLocationHandler();
    },[getMetricHandler, getSportsHandler, getLocationHandler, tagsplayersData])

    useEffect(()=> {
        getResponseTypeWithSportFilter(sportsForResponseType);
    },[sportsForResponseType, getResponseTypeWithSportFilter, dates]);

    useEffect(()=>{
        alertsRaisedBySport(alertRaisedBySportsSelection);
    // eslint-disable-next-line
    },[alertRaisedBySportsSelection, dates]);

    useEffect(() =>{
        responseRatesBySport(responseRatesBySportsSelection);
        // eslint-disable-next-line
    },[responseRatesBySportsSelection, dates]);

    useEffect(()=> {
        totalAlertsBySport(totalAlertsBySportsSelection);
        // eslint-disable-next-line
    },[totalAlertsBySportsSelection, dates]);

    useEffect(()=> {
        totalAlertsByLocation(totalAlertsByLocationSelection);
        // eslint-disable-next-line
    },[totalAlertsByLocationSelection, dates]);

    useEffect(()=> {
        tagsplayersData();
        // eslint-disable-next-line
    },[dates]);



    const footerContent = (
        <div>
            <PR.Button label="Cancel" onClick={() => setVisible(false)} outlined className="logout-btns" />
            <PR.Button label="Export" onClick={() => handleDownloadFile(exportType)} disabled={!exportType} outlined autoFocus className={`logout-btns click-btn`} />
        </div>
        );

        
    const chartRefAlertsRaised = useRef(null);
    const chartRefResponseRates = useRef(null);
    const chartRefResponseTypes = useRef(null);
    const chartRefTotalAlerts = useRef(null);
    const chartRefAlertsByLocation = useRef(null);
    const chartRefTennisPlayers = useRef(null);


    let chartRef, data, downloadedFilename;
    switch (type) {
       case 'arps':
           chartRef = chartRefAlertsRaised;
           data = alertRaisedData;
           downloadedFilename = "alert-raised";
           break;
       case 'rrps':
           chartRef = chartRefResponseRates;
           data = responseRatesData;
           downloadedFilename ="response-rates";
           break;
       case 'rtps':
           chartRef = chartRefResponseTypes;
           data = responseTypesData;
           downloadedFilename ="response-type";
           break;
       case 'taps':
           chartRef = chartRefTotalAlerts;
           data = totalAlertsData;
           downloadedFilename = "total-alerts";
           break;
       case 'tapl':
           chartRef = chartRefAlertsByLocation;
           data = alertsByLocationData;
           downloadedFilename = "alerts-by-location";
           break;
       case 'tpc':
           chartRef = chartRefTennisPlayers;
           data = tennisPlayersData;
           downloadedFilename = "tennis-players"
           break;
       default:
           chartRef = null;
           data = null;
           downloadedFilename = "analytics-data"
           break;
    }
    const items = [
        {
            label: 'View',
            icon: 'pi pi-eye',
            disabled: !Boolean(data),
            command: () => navigate(`/my-analytics-details?type=${type}`)
        },
        {
            label: 'Export',
            icon: 'pi pi-upload',
            disabled: !Boolean(data) || !isExport,
            command: () => setVisible(true)
        }
    ];

    const handleDownloadFile = (exportType) => {
        const currDate = `_${moment(dates[0]).format('DD_MMM_YYYY')}${moment(dates[1]).isValid() ? `_to_${moment(dates[1]).format('DD_MMM_YYYY')}` : ''}`;
        downloadedFilename =  downloadedFilename + currDate;

        const csvConfig = mkConfig({ useKeysAsHeaders: true , filename: downloadedFilename});

        if(exportType === 'png'){
            if(type === 'rtps'){
                exportFile("rtpsFileDownload", "png", downloadedFilename);
            } else if (chartRef.current) {
                const base64Image = chartRef.current.getBase64Image();
                const link = document.createElement('a');
                link.href = base64Image;
                link.download = `${downloadedFilename}`;
                link.click();
            }
        }
        else if(exportType === 'csv'){
            const csv = generateCsv(csvConfig)(data);
            download(csvConfig)(csv);
        }
        else if (exportType === 'pdf') {
            if(type === 'rtps'){
                exportFile("rtpsFileDownload", "pdf", downloadedFilename);
            } else {
                const base64Image = chartRef.current.getBase64Image();
                const doc = new jsPDF({
                    orientation: "landscape",
                    unit: "mm",
                    format: "a4",
                });
                doc.addImage(base64Image, 'PNG', 10, 10);
                doc.save(`${downloadedFilename}.pdf`);
            }
        }
        setType(null);
        setExportType(null);
        setVisible(false);
    }

    return (
        <>
            <Layout>
                <MenuBar/>
                <section className="my-analytics-tab">
                    <div className="m-0 flex align-items-center justify-content-between">
                        <div className='heading-card'>
                            <h1>Key Metrics</h1>
                        </div>
                        <div className="card">
                            <PR.Calendar 
                                value={dates} 
                                onChange={(e) => setDates(e.value)} 
                                selectionMode="range" 
                                iconPos="left" 
                                readOnlyInput 
                                showIcon 
                                className='date-calendar' 
                                placeholder='Date'
                                dateFormat="dd/mm/yy"
                                maxDate={new Date()}
                            />
                        </div>
                    </div>
                    <div className="analytics-wrapper">
                        <div className="grid">
                            <div className="col-4">
                                <div className="info-box">
                                    <div className="flex align-items-start">
                                        <div className='pr-2'>
                                            <h4>Total Alerts Raised</h4>
                                            <p>The number of alerts raised by your organisation in a given date range</p>
                                        </div>
                                    </div>
                                    <div className="flex mt-3 align-items-center">
                                        <h2>{keyMetrics?.alertsRaisedPerMember ? keyMetrics?.alertsRaisedPerMember[0]?.alertCount : 0}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="info-box">
                                    <div className="flex align-items-start">
                                        <div className='pr-2'>
                                            <h4>Response Rate</h4>
                                            <p>The % of alerts responded to by your organisation in a given date range</p>
                                        </div>
                                    </div>
                                    <div className="flex mt-3 align-items-center">
                                        <h2>{keyMetrics?.responseRatePerMember ? keyMetrics?.responseRatePerMember[0]?.responseRate.toFixed(1) : 0}%</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="info-box">
                                    <div className="flex align-items-start">
                                        <div className='pr-2'>
                                            <h4>Average Response Time</h4>
                                            <p>The average time it takes your organisation to respond to an alert in a given date range</p>
                                        </div>
                                    </div>
                                    <div className="flex mt-3 align-items-center justify-content-between">
                                        <h2>{keyMetrics?.averageResponseTimePerMember ? ((keyMetrics?.averageResponseTimePerMember[0]?.avg_response_time_seconds)/3600).toFixed(1) : 0} hrs</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="hr-line"></div>
                        <div className="grid mt-2">
                            {/* Alerts Raised by Sport */}
                            <div className="col-4">
                                <div className="info-box">
                                        <div className="flex align-items-center header justify-content-between">
                                            <div className='pr-2'>
                                                <span>Alert raised</span>
                                            </div>
                                            <div className="card flex gap-2 justify-content-center">
                                                <div className="card">
                                                    <PR.MultiSelect filter resetFilterOnHide value={alertRaisedBySportsSelection} onChange={(e) => setAlertRaisedBySportsSelection(e.value)} options={sports} optionLabel="name" 
                                                    placeholder="Select sports" maxSelectedLabels={0} className="multi-select-dropdown"
                                                    pt={{
                                                        checkboxContainer: {
                                                            onClick: (e) => {
                                                                e.stopPropagation();
                                                                const parent = e.target?.parentNode;
                                                                if (parent && typeof parent.click === 'function') {
                                                                    parent.click();
                                                                }
                                                            },
                                                        },
                                                    }} 
                                                    />
                                                </div>
                                                <PR.Menu model={items} popup ref={menuRight} id="popup_menu_right" className='export-popup' popupAlignment="right" />
                                                <PR.Button icon="pi pi-ellipsis-v" className="export-button" onClick={(event) => { setType('arps'); menuRight.current.toggle(event)}} aria-controls="popup_menu_right" aria-haspopup />
                                            </div>
                                        </div>
                                        <div className='pr-0'>
                                            <h4>Alerts Raised by Sport</h4>
                                            <p>The number of alerts raised by your organisation by sport in a given date range</p>
                                        </div>
                                        <Link to={`/my-analytics-details?type=arps`} className={`${!alertRaisedData ? 'inactive-link': 'active-link'} card mt-4`}>
                                            <PR.Chart type="bar" ref={chartRefAlertsRaised} data={alertRaisedBySportsChart} options={chartOptions} />
                                        </Link>
                                </div>
                            </div>
                            {/* Response Rate by Sport */}
                            <div className="col-4">
                                <div className="info-box">
                                    <div className="flex align-items-center header justify-content-between">
                                        <div className='pr-2'>
                                            <span>Response Rate</span>
                                        </div>
                                        <div className="card flex gap-2 justify-content-center">
                                            <div className="card">
                                                <PR.MultiSelect filter resetFilterOnHide value={responseRatesBySportsSelection} onChange={(e) => setResponseRatesBySportsSelection(e.value)} options={sports} optionLabel="name" 
                                                placeholder="Select sports" maxSelectedLabels={0} className="multi-select-dropdown" 
                                                pt={{
                                                    checkboxContainer: {
                                                        onClick: (e) => {
                                                            e.stopPropagation();
                                                            const parent = e.target?.parentNode;
                                                            if (parent && typeof parent.click === 'function') {
                                                                parent.click();
                                                            }
                                                        },
                                                    },
                                                }} />
                                            </div>
                                            <PR.Menu model={items} popup ref={menuRight} id="popup_menu_right" className='export-popup' popupAlignment="right" />
                                            <PR.Button icon="pi pi-ellipsis-v" className="export-button" onClick={(event) =>{setType('rrps'); menuRight.current.toggle(event)}} aria-controls="popup_menu_right" aria-haspopup />
                                        </div>
                                    </div>
                                    <div className='pr-0'>
                                        <h4>Response Rate by Sport</h4>
                                        <p>The % of alerts responded to by your organisation by sport in a given date range</p>
                                    </div>
                                    <Link to={`/my-analytics-details?type=rrps`} className={`${!responseRatesData ? 'inactive-link': 'active-link'} card mt-4`}>
                                        <PR.Chart type="bar" ref={chartRefResponseRates} data={responseRatesBySportsChart} options={chartOptionsWithPerc} />
                                    </Link>
                                </div>
                            </div>
                            {/* Response Type */}
                            <div className="col-4">
                                <div className="info-box">
                                    <div className="flex align-items-center header justify-content-between">
                                        <div className='pr-2'>
                                            <span>Response Type</span>
                                        </div>
                                        <div className="card flex gap-2 justify-content-center">
                                            <div className="card">
                                                <PR.Dropdown
                                                    value={sportsForResponseType}
                                                    onChange={(e) => setSportsForResponseType(e.value)}
                                                    options={sports}
                                                    optionLabel="name"
                                                    placeholder="Select sports"
                                                    className="single-select-dropdown"
                                                    showClear
                                                    filter
                                                    />
                                            </div>
                                            <PR.Menu model={items} popup ref={menuRight} id="popup_menu_right" className='export-popup' popupAlignment="right" />
                                            <PR.Button icon="pi pi-ellipsis-v" className="export-button" onClick={(event) => { setType('rtps'); menuRight.current.toggle(event)}} aria-controls="popup_menu_right" aria-haspopup />
                                        </div>
                                    </div>
                                    <div className='pr-0'>
                                        <h4>Response Type</h4>
                                        <p>A breakdown (in %) of the way in which your organisation has responded to alerts in a given date range</p>
                                    </div>
                                    <div ref={chartRefResponseTypes} id="rtpsFileDownload">
                                    {type === 'rtps' && <p></p>}
                                    <Link to={`/my-analytics-details?type=rtps`} className={`${!responseTypesData ? 'inactive-link': 'active-link'} card mt-2`}>
                                        <PR.Chart type="bar" data={responseTypeChartData} options={chartOptionsResponseType} className='horizontal-bar-chart' />
                                    </Link>
                                    {responseType?.responseTypePerSport ? (
                                    <div className='chart-info'>
                                    {responseType?.responseTypePerSport && responseType?.responseTypePerSport[0] && (
                                        <ul className='flex align-items-center justify-content-between list-none'>
                                            <li><span className='indicator'></span>{responseType?.responseTypePerSport ? formatResponseTypeLabel[responseType?.responseTypePerSport[0]?.responseType] : ''}</li>
                                            <li><b>{responseType?.responseTypePerSport ? responseType?.responseTypePerSport[0]?.responseRate.toFixed(2) : 0}%</b></li>
                                        </ul>
                                         )}
                                        {responseType?.responseTypePerSport && responseType?.responseTypePerSport[1] && (
                                        <ul className='flex align-items-center justify-content-between list-none'>
                                            <li><span className='indicator not-suspicious'></span>{responseType?.responseTypePerSport ? formatResponseTypeLabel[responseType?.responseTypePerSport[1]?.responseType] : ''}</li>
                                            <li><b>{responseType?.responseTypePerSport ? responseType?.responseTypePerSport[1]?.responseRate.toFixed(2) : 0}%</b></li>
                                        </ul>
                                        )}
                                       {responseType?.responseTypePerSport && responseType?.responseTypePerSport[2] && (
                                        <ul className='flex align-items-center justify-content-between list-none mb-0'>
                                            <li><span className='indicator not-offered'></span>{responseType?.responseTypePerSport ? formatResponseTypeLabel[responseType?.responseTypePerSport[2]?.responseType] : ''}</li>
                                            <li><b>{responseType?.responseTypePerSport ? responseType?.responseTypePerSport[2]?.responseRate.toFixed(2) : 0}%</b></li>
                                        </ul>
                                        )}
                                    </div>
                                    ):(
                                        <div className='flex justify-content-center'>
                                            <p className='mt-3'>No data available</p>
                                        </div>
                                    )}
                                    </div>
                                    
                                </div>
                            </div>
                            
                        </div>
                        <div className="grid mt-1">
                            {/* Total Alerts by Sport */}
                            <div className="col-4">
                                <div className="info-box">
                                    <div className="flex align-items-center header justify-content-between">
                                        <div className='pr-2'>
                                            <span>Total Alerts by Sport</span>
                                        </div>
                                        <div className="card flex gap-2 justify-content-center">
                                            <div className="card">
                                                <PR.MultiSelect filter resetFilterOnHide value={totalAlertsBySportsSelection} onChange={(e) => setTotalAlertsBySportsSelection(e.value)} options={sports} optionLabel="name" 
                                                    placeholder="Select sports" maxSelectedLabels={0} className="multi-select-dropdown"
                                                    pt={{
                                                        checkboxContainer: {
                                                            onClick: (e) => {
                                                                e.stopPropagation();
                                                                const parent = e.target?.parentNode;
                                                                if (parent && typeof parent.click === 'function') {
                                                                    parent.click();
                                                                }
                                                            },
                                                        },
                                                    }} 
                                                />
                                            </div>
                                            <PR.Menu model={items} popup ref={menuRight} id="popup_menu_right" className='export-popup' popupAlignment="right" />
                                            <PR.Button icon="pi pi-ellipsis-v" className="export-button" onClick={(event) => { setType('taps'); menuRight.current.toggle(event)}} aria-controls="popup_menu_right" aria-haspopup />
                                        </div>
                                    </div>
                                    <div className='pr-0'>
                                        <h4>Total Alerts by Sport</h4>
                                        <p>The number of alerts deemed suspicious by sport in a given date range</p>
                                    </div>
                                    <Link to={`/my-analytics-details?type=taps`} className={`${!totalAlertsData ? 'inactive-link': 'active-link'} card mt-4`}>
                                        <PR.Chart type="bar" ref={chartRefTotalAlerts} data={totalAlertsBySportsChart} options={chartOptions} />
                                    </Link>
                                </div>
                            </div>
                            {/* Total Alerts by Location */}
                            <div className="col-4">
                                <div className="info-box">
                                    <div className="flex align-items-center header justify-content-between">
                                        <div className='pr-2'>
                                            <span>Total Alerts by Location</span>
                                        </div>
                                        <div className="card flex gap-2 justify-content-center">
                                            <div className="card">
                                            <PR.MultiSelect filter resetFilterOnHide value={totalAlertsByLocationSelection} onChange={(e) => setTotalAlertsByLocationSelection(e.value)} options={location} optionLabel="name" 
                                                    placeholder="Select location" maxSelectedLabels={0} className="multi-select-dropdown" 
                                                    pt={{
                                                        checkboxContainer: {
                                                            onClick: (e) => {
                                                                e.stopPropagation();
                                                                const parent = e.target?.parentNode;
                                                                if (parent && typeof parent.click === 'function') {
                                                                    parent.click();
                                                                }
                                                            },
                                                        },
                                                    }} 
                                                />
                                            </div>
                                            <PR.Menu model={items} popup ref={menuRight} id="popup_menu_right" className='export-popup' popupAlignment="right" />
                                            <PR.Button icon="pi pi-ellipsis-v" className="export-button" onClick={(event) => { setType('tapl'); menuRight.current.toggle(event)}} aria-controls="popup_menu_right" aria-haspopup />
                                        </div>
                                    </div>
                                    <div className='pr-0'>
                                        <h4>Total Alerts by Location</h4>
                                        <p>The number of alerts deemed suspicious by event location in a given date range</p>
                                    </div>
                                    <Link to={`/my-analytics-details?type=tapl`} className={`${!alertsByLocationData ? 'inactive-link': 'active-link'} card mt-4`}>
                                        <PR.Chart type="bar" ref={chartRefAlertsByLocation} data={totalAlertsByLocationChart} options={chartOptions} />
                                    </Link>
                                </div>
                            </div>
                            {/* Tennis Players of Concern */}
                            <div className="col-4">
                                <div className="info-box">
                                    <div className="flex align-items-center header justify-content-between">
                                        <div className='pr-2'>
                                            <span>Tennis Players</span>
                                        </div>
                                        <div className="card">
                                            <PR.Menu model={items} popup ref={menuRight} id="popup_menu_right" className='export-popup' popupAlignment="right" />
                                            <PR.Button icon="pi pi-ellipsis-v" className="export-button" onClick={(event) => { setType('tpc'); menuRight.current.toggle(event)}} aria-controls="popup_menu_right" aria-haspopup />
                                        </div>
                                    </div>
                                    <div className='pr-0'>
                                        <h4>Tennis Players of Concern</h4>
                                        <p>Tennis players that have featured in multiple IBIA alerts deemed suspicious in a given date range</p>
                                    </div>
                                    <Link to={`/my-analytics-details?type=tpc`} className={`${!tennisPlayersData ? 'inactive-link': 'active-link'} card mt-4`}>
                                        <PR.Chart type="bar" ref={chartRefTennisPlayers} data={tennisPlayersByTagsChart} options={chartOptions} />
                                    </Link>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </section>
            </Layout>
            <PR.Dialog visible={visible} footer={footerContent} className="export-dialog">
            <div className="export-header-section">
                <h2>Export Graph as</h2>
                <p>Select file format</p>
            </div>
            <div className="export-body-section">
                <PR.Button label="PNG" onClick={() => setExportType('png')} outlined className={`${exportType === 'png' ? 'export-highlight' : '' } export-btns`} />
                <PR.Button label="CSV" onClick={() => setExportType('csv')} outlined className={`${exportType === 'csv' ? 'export-highlight' : '' } export-btns`} />
                <PR.Button label="PDF" onClick={() => setExportType('pdf')} outlined className={`${exportType === 'pdf' ? 'export-highlight' : '' } export-btns`} />
            </div>
            </PR.Dialog>
        </>
    );
};

export default MyAnalytics;