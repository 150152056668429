import React, { useEffect, useState } from "react";
import * as PR from "../../prime-react/index";
import { useSelector } from "react-redux";

const AppliedFilter = ({selectedFilters, setFilters, filterVisible, filterHideShow}) => {
    const [editedFilters, setEditedFilters] = useState(selectedFilters);
    const [locationError,setLocationError] = useState('');

    const role = useSelector(state => state.auth.role);

    // Sync editedFilters with selectedFilters on changes
    useEffect(() => {
        setEditedFilters(selectedFilters);

    }, [selectedFilters]);

    const othersFiltersSelected = () => {
        const filters = [
            selectedFilters?.channel,
            selectedFilters?.timeOfBet,
            selectedFilters?.stateOfBet,
            selectedFilters?.timeOfAlert,
            selectedFilters?.maxBets,
        ]
        return filters.some(filter => filter?.length > 0);
    }

    const filterTabsSelected = () => {
        const filters = [
            selectedFilters?.keyword,
            selectedFilters?.combinedStatus,
            selectedFilters?.tags,
            selectedFilters?.sports,
            selectedFilters?.tournamentLevels,
            selectedFilters?.alertType,
            selectedFilters?.company,
            selectedFilters?.responseType,
            selectedFilters?.date?.date,
            selectedFilters?.eventDate?.date,
            selectedFilters?.tournament,
            selectedFilters?.gender,
            selectedFilters?.location,
            selectedFilters?.customerLocations,
            selectedFilters?.receiver,
            selectedFilters?.reportType
        ];

        return filters.some(filter => filter?.length > 0);
    }

    const applyFilters = () => {
        if(editedFilters?.sports?.length && editedFilters?.location?.length){
            const sports =  editedFilters?.sports.map(s => s.name);
            const locations =  editedFilters?.location.map(s => s.name);
            if(!sports.includes('eSports') && locations.includes('No Country')){
                setLocationError('Invalid Search')
                setTimeout(() => {
                  setLocationError('')
                },2000)
                return;
            }
        }
        setFilters(editedFilters)

    }

    const resetFilters = () => {
        setFilters({})
    }

    const handleRemove = (propertyKey, propertyId) => {
        let newObj ={}
        if(propertyKey==='keyword') newObj.keyword = '';
        else if(propertyKey === "date" || propertyKey === "eventDate"){
            newObj = {   
                [propertyKey] :{
                    date:[],
                    dateRange:undefined
                }
            }
        }else {
            if(propertyKey === "combinedStatus"){
                const alertPropertyId = propertyId.split('-')[0]
                const casePropertyId = propertyId.split('-')[1]

                const alertPropertyList = editedFilters["alertStatus"]?.filter(property => property.id !== alertPropertyId)
                const casePropertyList = editedFilters["caseStatus"]?.filter(property => property.id !== casePropertyId)
                const propertyList = editedFilters[propertyKey]?.filter(property => property.id !== propertyId)
                newObj = {
                    "alertStatus" : alertPropertyList,
                    "caseStatus" : casePropertyList,
                    "combinedStatus": propertyList
                }

                const updated = { ...editedFilters, ...newObj }

                setEditedFilters(updated);

                return;

            }
            const propertyList = editedFilters[propertyKey]?.filter(property => property.id !== propertyId)
            newObj = {
                [propertyKey] : propertyList
            }
        }

        const updated = { ...editedFilters, ...newObj }
        const tennisExists = updated.sports.some(sport => sport.name === "Tennis");

        if (!tennisExists) {
            updated.tournamentLevels = [];
        }

        // Create a set of sportIDs to be excluded
        const excludedSportIDs = new Set(updated.sports.map(sport => sport.id));

        // Filter the tournament array by excluding those that match the sportID
        const filteredTournaments = updated.tournament.filter(tournament => excludedSportIDs.has(tournament.sportID));

        if(updated.sports?.length === 0) {
            updated.tournament = [];
        } else {
            updated.tournament = filteredTournaments;
        }

        setEditedFilters(updated);
    }
    return (
        <>
            {(
                filterTabsSelected() ||
                othersFiltersSelected()
            ) && (<div className='filter-applied-section'>
                    <>  
                        <div className="flex align-items-center justify-content-between">
                            <p>Filters applied</p>
                            <div className="flex gap-3 search-filters-button">
                                {filterVisible && 
                                <>
                                    <PR.Button label="Reset Filters" onClick={resetFilters} className="reset-button" />
                                    <PR.Button label="Apply Filters" onClick={applyFilters} className={role === 'analyst' ? 'apply-button color-update' : 'apply-button'} />
                                </>}
                                <PR.Button className="filter_toggle_icon" outlined icon={(filterVisible) ? 'pi pi-chevron-down' : 'pi pi-chevron-up'} onClick={filterHideShow}>
                                </PR.Button>
                            </div>
                        </div>
                        {filterVisible && <hr/>}
                    </>
                {filterVisible && <>
                    {editedFilters.length > 0 && <hr/>}
                {/* Status */}
                {locationError && <div className='filters-list mb-3'><ul><li className="error-message" style={{color:'red'}}>{locationError}</li></ul></div>}
                {editedFilters?.keyword?.length>0 && <div className='filters-list mb-3'>
                    <ul>
                        <li>Keyword: </li>
                      <li><span>{editedFilters.keyword} <i className="pi pi-times" onClick={() => handleRemove("keyword")}></i></span></li>
                    </ul>
                </div>}
                {editedFilters?.combinedStatus?.length>0 && <div className='filters-list mb-3'>
                    <ul>
                        <li>Status: </li>
                        {editedFilters.combinedStatus.map((alert,index) => (<li key={index}><span>{alert.name} <i className="pi pi-times" onClick={() => handleRemove("combinedStatus", alert.id)}></i></span></li>))}
                    </ul>
                </div>}
                {editedFilters?.reportType?.length > 0 && <div className='filters-list mb-3'>
                    <ul>
                        <li>Report Type:</li>
                        {editedFilters?.reportType?.map((reportType, index) => (<li key={index}><span>{reportType.name} <i className="pi pi-times" onClick={() => handleRemove("reportType", reportType.id)}></i></span></li>))}
                    </ul>
                </div>}
                {/* Receiver */}
                {editedFilters?.receiver?.length > 0 && <div className='filters-list mb-3'>
                    <ul>
                        <li>Receiver:</li>
                        {editedFilters.receiver.map((receiver, index) => (<li key={index}><span>{receiver.name} <i className="pi pi-times" onClick={() => handleRemove("receiver", receiver.id)}></i></span></li>))}
                    </ul>
                </div>}
                {/* Tags */}
                {editedFilters?.tags?.length>0 && <div className='filters-list mb-3'>
                    <ul>
                        <li>Player / Team: </li>
                        {editedFilters.tags.map((player,index) => (<li key={index}><span>{player.name} <i className="pi pi-times" onClick={() => handleRemove("tags", player.id)}></i></span></li>))}
                    </ul>
                </div>}

                {/* Sports */}
                {editedFilters?.sports?.length>0 && <div className='filters-list mb-3'>
                    <ul>
                        <li>Sports:</li>
                        {editedFilters.sports.map((sport,index) => (<li key={index}><span>{sport.name} <i className="pi pi-times" onClick={() => handleRemove("sports", sport.id)}></i></span></li>))}
                    </ul>
                </div>}

                {/* tournament Levels */}
                {editedFilters?.tournamentLevels?.length>0 && <div className='filters-list mb-3'>
                    <ul>
                        <li>tournament Levels:</li>
                        {editedFilters.tournamentLevels.map((tournamentLevel,index) => (<li key={index}><span>{tournamentLevel.name} <i className="pi pi-times" onClick={() => handleRemove("tournamentLevels", tournamentLevel.id)}></i></span></li>))}
                    </ul>
                </div>}

                {/* Alert type */}
                {editedFilters?.alertType?.length>0 && <div className='filters-list mb-3'>
                    <ul>
                        <li>Alert type:</li>
                        {editedFilters.alertType.map((alertType,index) => (<li key={index}><span>{alertType.name} <i className="pi pi-times" onClick={() => handleRemove("alertType", alertType.id)}></i></span></li>))}
                    </ul>
                </div>}
                {/* Company */}
                {editedFilters?.company?.length>0 && <div className='filters-list mb-3'>
                    <ul>
                        <li>Company:</li>
                        {editedFilters.company.map((company,index) => (<li key={index}><span>{company.name} <i className="pi pi-times" onClick={() => handleRemove("company", company.id)}></i></span></li>))}
                    </ul>
                </div>}

                {/* Response Type */}
                {editedFilters?.responseType?.length>0 && <div className='filters-list mb-3'>
                    <ul>
                        <li>Response Type:</li>
                        {editedFilters.responseType.map((responseType,index) => (<li key={index}><span>{responseType.name} <i className="pi pi-times" onClick={() => handleRemove("responseType", responseType.id)}></i></span></li>))}
                    </ul>
                </div>}

                {/* Date */}
                {editedFilters?.date && editedFilters?.date?.dateRange !== '' && editedFilters?.date?.dateRange !== undefined && <div className='filters-list mb-3'>
                    <ul>
                        <li>Created Date:</li>
                        <li><span>{editedFilters.date.dateRange} <i className="pi pi-times" onClick={() => handleRemove("date", editedFilters.date.dateRange)}></i></span></li>
                    </ul>
                </div>}
                {/* Event Date */}
                {editedFilters?.eventDate && editedFilters?.eventDate?.dateRange !== '' && editedFilters?.eventDate?.dateRange !== undefined && <div className='filters-list mb-3'>
                    <ul>
                        <li>Event Date:</li>
                        <li><span>{editedFilters.eventDate.dateRange} <i className="pi pi-times" onClick={() => handleRemove("eventDate", editedFilters.eventDate.dateRange)}></i></span></li>
                    </ul>
                </div>}
                {/* Tournament-League */}
                {editedFilters?.tournament?.length>0 && <div className='filters-list mb-3'>
                    <ul>
                        <li>Tournament / League:</li>
                        {editedFilters.tournament.map((tournament,index) => (<li key={index}><span>{tournament.name} <i className="pi pi-times" onClick={() => handleRemove("tournament", tournament.id)}></i></span></li>))}
                    </ul>
                </div>}
                {/* Gender */}
                {editedFilters?.gender?.length>0 && <div className='filters-list mb-3'>
                    <ul>
                        <li>Gender:</li>
                        {editedFilters.gender.map((gender,index) => (<li key={index}><span>{gender.name} <i className="pi pi-times" onClick={() => handleRemove("gender", gender.id)}></i></span></li>))}
                    </ul>
                </div>}
                {/* Location */}
                {editedFilters?.location?.length>0 && <div className='filters-list mb-3'>
                    <ul>
                        <li>Location:</li>
                        {editedFilters.location.map((location,index) => (<li key={index}><span>{location.name} <i className="pi pi-times" onClick={() => handleRemove("location", location.id)}></i></span></li>))}
                    </ul>
                </div>}
                {/* Customer Location */}
                {editedFilters?.customerLocations?.length>0 && <div className='filters-list mb-3'>
                    <ul>
                        <li>Customer Location:</li>
                        {editedFilters.customerLocations.map((location,index) => (<li key={index}><span>{location.name} <i className="pi pi-times" onClick={() => handleRemove("customerLocations", location.id)}></i></span></li>))}
                    </ul>
                </div>}
                {/* Other */}
                {(
                    othersFiltersSelected()
                ) &&
                <div className='filters-list mb-0'>
                    <ul className='other-ul'>
                        <li>Other:</li>


                        {editedFilters?.channel?.length>0 && <li>
                            Channel 
                            {editedFilters.channel.map((channel,index) => (<span key={index}>{channel.name} <i className="pi pi-times" onClick={() => handleRemove("channel", channel.id)}></i></span>))}
                        </li>}


                        {editedFilters?.timeOfBet?.length>0 && <li>
                            Time of bet 
                            {editedFilters.timeOfBet.map((timeOfBet,index) => (<span key={index}>{timeOfBet.name} <i className="pi pi-times" onClick={() => handleRemove("timeOfBet", timeOfBet.id)}></i></span>))}
                        </li>}


                        {editedFilters?.stateOfBet?.length>0 && <li>
                            State of bets
                            {editedFilters.stateOfBet.map((stateOfBet,index) => (<span key={index}>{stateOfBet.name} <i className="pi pi-times" onClick={() => handleRemove("stateOfBet", stateOfBet.id)}></i></span>))}
                        </li>}


                        {editedFilters?.timeOfAlert?.length>0 && <li>
                            Alert raised at
                            {editedFilters.timeOfAlert.map((timeOfAlert,index) => (<span key={index}>{timeOfAlert.name} <i className="pi pi-times" onClick={() => handleRemove("timeOfAlert", timeOfAlert.id)}></i></span>))}
                        </li>}


                        {editedFilters?.maxBets?.length>0 && <li>
                            Max bets
                            {editedFilters.maxBets.map((maxBets,index) => (<span key={index}>{maxBets.name} <i className="pi pi-times" onClick={() => handleRemove("maxBets", maxBets.id)}></i></span>))}
                        </li>}

                        {/* <li>Type of customer <span>New <i className="pi pi-times"></i></span> <span>Regular <i className="pi pi-times"></i></span></li> */}
                    </ul>
                </div>}
                </>}
            </div>)}
        </>
    );
};

export default AppliedFilter;